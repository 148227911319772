import "./App.css";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuthContext } from "./context/authcontext";
import { NotificationContextProvider } from "./context/notificationcontext";
import { ConfigProvider } from "./context/configcontext";

import LoadingOverlay from "./components/loadingoverlay";
import ProtectedRoute from "./components/route/protectedroute";
import ProfileModal from "./components/editmodals/profilemodal";
import DirectoryInformationPage from "./components/pages/DMS/directoryinfopage";
import FileInformationPage from "./components/pages/DMS/fileinformationpage";

import AccountTemplate from "./templates/accounttemplate";
import MainTemplate from "./templates/maintemplate";
import ListPageTemplate from "./templates/listpagetemplate";
import LandingPageTemplate from "./templates/landingpagetemplate";

import LoginPage from "./pages/account/login";
import Dashboard from "./pages/cts/dashboard";
import CorrespondenceDetails from "./pages/cts/correspondence/correspondencedetails";
import Reports from "./pages/cts/reports";
import EntityTypes from "./pages/cts/settings/entitytypes";
import UserGroups from "./pages/usergroups";
import PrivacyLevels from "./pages/cts/settings/privacylevels";
import ImportanceLevels from "./pages/cts/settings/importancelevel";
import Entity from "./pages/cts/settings/entity";
import DomainTypes from "./pages/cts/settings/domaintypes";
import ContactTypes from "./pages/cts/settings/contacttypes";
import ReferenceTypes from "./pages/cts/settings/referencetypes";
import CorrespondenceTypes from "./pages/cts/settings/correspondencetypes";
import Category from "./pages/cts/settings/category";
import UserList from "./pages/users";
import Attachment from "./pages/cts/attachment";
import GetEmail from "./pages/account/forgetPassword/getEmail";
import ResetPassword from "./pages/account/forgetPassword/resetPassword";
import RoutingGroup from "./pages/cts/routinggroup";
import UserGroupDetails from "./pages/usergroupdetails";
import DMSExplorer from "./pages/dms/dms";
import Cabinet from "./pages/dms/settings/cabinets";
import StructureTemplates from "./pages/dms/settings/structureTemplates";
import DocumentProfiles from "./pages/dms/settings/documentProfiles";
import DocumentTypes from "./pages/dms/settings/documentTypes";
import DocumentCategories from "./pages/dms/settings/documentCategories";
import ContentEditingRequests from "./pages/dms/tools/contenteditingrequests";
import DocumentEditorPage from "./pages/dms/tools/documentEditor";
import DocumentTemplates from "./pages/cts/documenttemplate/documenttemplates";
import DocumentTemplate from "./pages/cts/documenttemplate/documenttemplate";
import AttachmentSearch from "./pages/dms/tools/attachmentsearch";
import DMSReports from "./pages/dms/reports";
import Correspondence from "./pages/cts/correspondence/correspondence";
import Delegations from "./pages/cts/delegations/delegations";
import UserRoles from "./pages/userroles";
import LandingPage from "./pages/landingpage";
import VMSDashboard from "./pages/vms/vmsdashboard";
import Appointments from "./pages/vms/appointments/appointments";
import Workflows from "./pages/vms/workflows";
import AppointmentDetails from "./pages/vms/appointments/appointmentdetails";
import Checkins from "./pages/vms/checkins/checkins";

import useRefreshToken from "./shared/hooks/userefreshtoken";
import LogsPage from "./pages/cts/logs";

function App() {
  return (
    <ConfigProvider>
      <NotificationContextProvider>
        <AuthProvider>
          <Router>
            <ApplicationRoutes />
          </Router>
        </AuthProvider>
      </NotificationContextProvider>
    </ConfigProvider>
  );
}

const ApplicationRoutes = () => {
  const { authInitialized } = useAuthContext();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { isLoggedIn, fullToken, userData, signOut } = useAuthContext();
  const refresh = useRefreshToken();
  const location = useLocation();

  const refreshToken = () => {
    if (userData?.rememberMe) {
      refresh()
        .then(() => {
          console.log("refreshed");
        })
        .catch(() => {
          signOut();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      signOut();
    }
  };

  useEffect(() => {
    if (authInitialized && !isLoggedIn) {
      setLoading(false);
    } else if (authInitialized && isLoggedIn && fullToken) {
      const currentDateTime = new Date().getTime();
      const expiryDateTime = new Date(fullToken.expiryDate).getTime();
      if (expiryDateTime < currentDateTime) {
        refreshToken();
      } else if (
        location.pathname === "/" ||
        location.pathname === "/forget-password"
      ) {
        setLoading(false);
        //to redirect to dashboard if user is logged in and tries to access login page
        userData?.module === "DMS"
          ? navigate("/dms/documents")
          : userData?.module === "VMS"
          ? navigate("/vms/dashboard")
          : navigate("/cts/dashboard");
      } else {
        setLoading(false);
      }
    }
  }, [location.pathname, isLoggedIn, authInitialized]);

  if (loading) return <LoadingOverlay />;
  else
    return (
      <Routes>
        <Route element={<AccountTemplate />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forget-password" element={<GetEmail />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <LandingPageTemplate />
            </ProtectedRoute>
          }
        >
          <Route path="/welcome" element={<LandingPage />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <MainTemplate />
            </ProtectedRoute>
          }
        >
          <Route path="/userprofile" element={<ProfileModal />} />
          <Route path="/groupdetails/:id" element={<UserGroupDetails />} />
          {/* cts urls */}
          <Route path="/cts/dashboard" element={<Dashboard />} />
          <Route path="/cts/correspondence" element={<Correspondence />} />
          <Route path="/cts/reports" element={<Reports />} />
          <Route path="/cts/attachment" element={<Attachment />} />
          <Route
            path="/cts/correspondencedetails/:id"
            element={<CorrespondenceDetails />}
          />
          <Route path="/cts/documenttemplate" element={<DocumentTemplate />} />
          <Route path="/cts/logs" element={<LogsPage />} />

          {/* dms */}
          <Route path="/dms/documents" element={<DMSExplorer />} />
          <Route path="/dms/reports" element={<DMSReports />} />
          <Route path="/dms/documents/:id" element={<FileInformationPage />} />
          <Route
            path="/dms/directory/:id"
            element={<DirectoryInformationPage />}
          />

          {/* vms (visitor management system) */}
          <Route path="/vms/dashboard" element={<VMSDashboard />} />
          <Route path="/vms/appointments" element={<Appointments />} />
          <Route
            path="/vms/appointmentdetails/:id"
            element={<AppointmentDetails />}
          />
          <Route path="/vms/checkins" element={<Checkins />} />
          <Route element={<ListPageTemplate />}>
            <Route path="/userroles" element={<UserRoles />} />
            <Route path="/usergroup" element={<UserGroups />} />
            <Route path="/users" element={<UserList />} />

            {/* cts list pages */}

            <Route path="/cts/delegations" element={<Delegations />} />
            <Route path="/cts/entitytypes" element={<EntityTypes />} />
            <Route path="/cts/privacylevels" element={<PrivacyLevels />} />
            <Route
              path="/cts/importancelevels"
              element={<ImportanceLevels />}
            />
            <Route path="/cts/entities" element={<Entity />} />
            <Route path="/cts/domaintypes" element={<DomainTypes />} />
            <Route path="/cts/contacttypes" element={<ContactTypes />} />
            <Route path="/cts/referencetypes" element={<ReferenceTypes />} />
            <Route
              path="/cts/correspondencetypes"
              element={<CorrespondenceTypes />}
            />
            <Route path="/cts/category" element={<Category />} />
            <Route path="/cts/routinggroup" element={<RoutingGroup />} />
            <Route path="/cts/templates" element={<DocumentTemplates />} />

            {/* dms list pages */}
            <Route path="/dms/cabinets" element={<Cabinet />} />
            <Route
              path="/dms/structuretemplates"
              element={<StructureTemplates />}
            />
            <Route
              path="/dms/documentprofiles"
              element={<DocumentProfiles />}
            />

            <Route path="/dms/documenttypes" element={<DocumentTypes />} />

            <Route
              path="/dms/documentcategories"
              element={<DocumentCategories />}
            />

            {/* vms list pages */}
            <Route path="/vms/workflows" element={<Workflows />} />

            {/* tools */}
            <Route
              path="/contenteditingrequests"
              element={<ContentEditingRequests />}
            />
            <Route path="/documenteditor" element={<DocumentEditorPage />} />
            <Route path="/attachmentsearch" element={<AttachmentSearch />} />
          </Route>

          {/* to redirect user to dashboard if he tries to access invalid url */}
          <Route
            path="*"
            element={
              <Navigate
                to={
                  userData?.module === "DMS"
                    ? "/dms/documents"
                    : userData?.module === "VMS"
                    ? "/vms/dashboard"
                    : "/cts/dashboard"
                }
                replace
              />
            }
          />
        </Route>
      </Routes>
    );
};

export default App;
