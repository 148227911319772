import useSecureAPI from "../hooks/usesecureapi";

const useVmsInboxService = () => {
  const secureAPI = useSecureAPI();

  const getInboxByUser = async (id: any) => {
    const response = await secureAPI.get(`vms/Inbox/byuser/${id}`);
    return response.data;
  };

  const getInboxByUserAndAppointment = async (
    id: any,
    appointmentId: number
  ) => {
    const response = await secureAPI.get(
      `vms/Inbox/byuser/byappointment/${id}/${appointmentId}`
    );
    return response.data;
  };

  const markAsRead = async (ids: any) => {
    const response = await secureAPI.put(`vms/Inbox/markasopened`, ids);
    return response.data;
  };

  const markAsUnread = async (ids: any) => {
    const response = await secureAPI.put(`vms/Inbox/markasunopened`, ids);
    return response.data;
  };

  const markAsArchived = async (ids: any) => {
    const response = await secureAPI.put(`vms/Inbox/archive`, ids);
    return response.data;
  };

  const deleteInbox = async (ids: any) => {
    const response = await secureAPI.put(`vms/Inbox/delete`, ids);
    return response.data;
  };

  return {
    getInboxByUser,
    getInboxByUserAndAppointment,
    markAsRead,
    markAsUnread,
    markAsArchived,
    deleteInbox,
  };
};

export default useVmsInboxService;
