import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Typography,
} from "@mui/material";
import ButtonDX from "../../../controls/buttondx";
import BoxDX from "../../../layout/boxdx";

import { usePDF } from "react-to-pdf";
import moment from "moment";

import logo from "../../../../assets/hexmate_logo.png";
import LoadingButtonDX from "../../../controls/loadingbuttondx";
import {
  concatenateNameWithRole,
  DateFormatter,
  downloadPDF,
} from "../../../../shared/globals";
import { useAuthContext } from "../../../../context/authcontext";
import { useTranslation } from "react-i18next";
import TypeTranslator from "../../../../shared/typetranslator";
import GridDX from "../../../layout/griddx";

const AppointmentReport = (props: any) => {
  const { open, onClose, appointmentData, countries } = props;
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { t, i18n } = useTranslation();
  const { getAppointmentTypeValue } = TypeTranslator();
  const { toPDF, targetRef } = usePDF({
    filename: t(`Appointment - ${appointmentData?.appointmentId}`),
    page: {
      format: "a4", // You can choose 'a4' or other formats
      orientation: "portrait", // 'portrait' or 'landscape'
    },
  });

  const handlePrintQuotation = async () => {
    downloadPDF({ toPDF, setIsPdfGenerating, onClose });
  };

  const VistorTableHeadRow = () => (
    <TableRow className="table-header">
      <TableCell
        width={200}
        sx={{
          border: 1,
          textAlign: "center",
          backgroundColor: "#DAE9F7",
        }}
      >
        {t("Visitor Name")}
      </TableCell>
      <TableCell
        sx={{
          border: 1,
          textAlign: "center",
          backgroundColor: "#DAE9F7",
        }}
      >
        {t("Nationality")}
      </TableCell>
      <TableCell
        sx={{
          border: 1,
          textAlign: "center",
          backgroundColor: "#DAE9F7",
        }}
      >
        {t("Contact Number")}
      </TableCell>
      <TableCell
        sx={{
          border: 1,
          textAlign: "center",
          backgroundColor: "#DAE9F7",
        }}
      >
        {t("Vehicle Registration Number")}
      </TableCell>
      <TableCell
        sx={{
          border: 1,
          textAlign: "center",
          backgroundColor: "#DAE9F7",
        }}
      >
        {t("Vehicle Color")}
      </TableCell>
    </TableRow>
  );
  const VisitorTableBody = ({ visitor }: any) => {
    return (
      <TableRow>
        <TableCell sx={{ border: 1 }}>
          <Typography sx={{ textAlign: "center", fontFamily: "Almarai" }}>
            {visitor?.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ border: 1 }}>
          <Typography sx={{ textAlign: "center", fontFamily: "Almarai" }}>
            {i18n.language === "en"
              ? countries.find(
                  (country: any) => country.countryId === visitor?.countryId
                )?.enName
              : countries.find(
                  (country: any) => country.countryId === visitor?.countryId
                )?.arName ||
                countries.find(
                  (country: any) => country.countryId === visitor?.countryId
                )?.enName}
          </Typography>
        </TableCell>
        <TableCell sx={{ border: 1 }}>
          <Typography sx={{ textAlign: "center", fontFamily: "Almarai" }}>
            {visitor?.contactNumber}
          </Typography>
        </TableCell>
        <TableCell sx={{ border: 1 }}>
          <Typography sx={{ textAlign: "center", fontFamily: "Almarai" }}>
            {visitor?.vehicleRegistrationNumber}
          </Typography>
        </TableCell>
        <TableCell sx={{ border: 1 }}>
          <Typography sx={{ textAlign: "center", fontFamily: "Almarai" }}>
            {visitor?.vehicleColor}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    appointmentData &&
    open && (
      <Dialog
        open={open}
        onClose={!isPdfGenerating && onClose}
        maxWidth={props.maxWidth ?? "xl"}
        disableEscapeKeyDown
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {t("Appointment Report")}
        </DialogTitle>
        <DialogActions
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <ButtonDX
            disabled={isPdfGenerating}
            variant="outlined"
            onClick={onClose}
            sx={{ mb: { xs: 2, sm: "auto" } }}
          >
            {props.cancelLabel ?? t("Cancel")}
          </ButtonDX>
          <LoadingButtonDX
            color="primary"
            onClick={handlePrintQuotation}
            loading={isPdfGenerating}
            sx={{ maxWidth: 120 }}
          >
            {t("Print")}
          </LoadingButtonDX>
        </DialogActions>
        <DialogContent
          sx={{
            overflowX: "hidden",
          }}
        >
          <div ref={targetRef}>
            {appointmentData.visitors.map((visitor: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "1024px",
                    height: "1440px",
                    padding: "50px 0px",
                    // margin: "50px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: "hidden",
                    // height of a A4 paper
                    // height: "11.69in",
                    //1122.24px
                  }}
                >
                  <div className="section">
                    <Typography
                      fontSize={20}
                      sx={{
                        textAlign: "center",
                        textDecoration: "underline",
                      }}
                    >
                      بسم الله الرحمن الرحيم
                    </Typography>
                    <BoxDX
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        my: 3,
                      }}
                    >
                      <img
                        width={160}
                        style={{ objectFit: "cover" }}
                        src={logo}
                        alt="Hexmate"
                      />
                    </BoxDX>
                    <Typography
                      variant="body2"
                      fontSize={16}
                      sx={{ textAlign: "center", mt: 1 }}
                    >
                      {t("Number")}: {appointmentData?.appointmentId}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={16}
                      sx={{ textAlign: "center", mt: 1 }}
                    >
                      {t("Unified Military Command")} {t("Date")} :{" "}
                      {DateFormatter(
                        moment(),
                        2,
                        gregorianDateFormatId,
                        hijriDateFormatId
                      )}{" "}
                      {t("H")}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={16}
                      sx={{ textAlign: "center", mt: 1 }}
                    >
                      {t("Intelligence and Security Authority")} {t("Date")} :{" "}
                      <span
                        style={{
                          // @ts-ignore
                          direction: "ltr !important",
                          display: "inline-block",
                        }}
                      >
                        {DateFormatter(
                          moment(),
                          1,
                          gregorianDateFormatId,
                          hijriDateFormatId
                        )}
                      </span>{" "}
                      {i18n.language === "ar" ? "هـ" : "H"}
                    </Typography>
                  </div>

                  <Table
                    className="section"
                    sx={{
                      minWidth: 650,
                      maxWidth: 800,
                      mt: 2,
                      "& .MuiTableCell-root": {
                        py: 0.8,
                      },
                      direction: i18n.dir(),
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          width={200}
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Appointment Type")}
                        </TableCell>
                        <TableCell width={200} sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          >
                            {getAppointmentTypeValue(
                              appointmentData?.appointmentTypeId
                            )}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Organization Name")}
                        </TableCell>
                        <TableCell width={200} sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          >
                            {appointmentData?.organizationName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Purpose of visit")}
                        </TableCell>
                        <TableCell sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          >
                            {appointmentData?.visitPurpose}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Visit Location")}
                        </TableCell>
                        <TableCell sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          >
                            {appointmentData?.visitLocation}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Permit Start Date")}
                        </TableCell>
                        <TableCell sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          >
                            {DateFormatter(
                              appointmentData?.visitFromDate,
                              calendarId,
                              gregorianDateFormatId,
                              hijriDateFormatId
                            )}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Permit End Date")}
                        </TableCell>
                        <TableCell sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          >
                            {DateFormatter(
                              appointmentData?.visitEndDate,
                              calendarId,
                              gregorianDateFormatId,
                              hijriDateFormatId
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          // colSpan={}
                          sx={{
                            border: 0,
                            borderBottom: 1,
                          }}
                        ></TableCell>
                        <TableCell
                          colSpan={3}
                          sx={{
                            border: 1,
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                              color: "red",
                            }}
                          >
                            {t(
                              "or temporary permit specifying working hours during official working hours; otherwise, mention in the notes"
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Permit Issuer Notes")}
                        </TableCell>
                        <TableCell colSpan={3} sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          ></Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Table
                    sx={{
                      minWidth: 650,
                      maxWidth: 800,
                      mt: 2,
                      "& .MuiTableCell-root": {
                        py: 0.8,
                      },
                      direction: i18n.dir(),
                    }}
                  >
                    <TableBody>
                      <VistorTableHeadRow />
                      <VisitorTableBody visitor={visitor} />
                    </TableBody>
                  </Table>
                

                  <Table
                    className="section"
                    sx={{
                      minWidth: 650,
                      maxWidth: 800,
                      mt: 2,
                      "& .MuiTableCell-root": {
                        py: 0.8,
                      },
                      direction: i18n.dir(),
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                              fontWeight: "bold",
                            }}
                          >
                            {t("Data of the committer/requester")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          width={200}
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Committer's Name")}
                        </TableCell>
                        <TableCell colSpan={3} sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          >
                            {concatenateNameWithRole(
                              appointmentData?.createdByUser
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Date")}
                        </TableCell>
                        <TableCell colSpan={3} sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          >
                            {DateFormatter(
                              appointmentData?.createdDate,
                              calendarId,
                              gregorianDateFormatId,
                              hijriDateFormatId
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Table
                    className="section"
                    sx={{
                      minWidth: 650,
                      maxWidth: 800,
                      mt: 2,
                      "& .MuiTableCell-root": {
                        py: 0.8,
                      },
                      direction: i18n.dir(),
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                              fontWeight: "bold",
                            }}
                          >
                            {t(
                              "Security officer at the Intelligence and Security Authority"
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          width={200}
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Name")}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Status (Approve - Reject)")}
                        </TableCell>

                        <TableCell
                          width={200}
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Date")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell height={30} sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          ></Typography>
                        </TableCell>

                        <TableCell sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          ></Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Table
                    className="section"
                    sx={{
                      minWidth: 650,
                      maxWidth: 800,
                      mt: 2,
                      "& .MuiTableCell-root": {
                        py: 0.8,
                      },
                      direction: i18n.dir(),
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          width={200}
                          sx={{
                            border: 1,
                            textAlign: "center",
                            backgroundColor: "#DAE9F7",
                          }}
                        >
                          {t("Notes")}
                        </TableCell>
                        <TableCell colSpan={3} sx={{ border: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Almarai",
                            }}
                          ></Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <GridDX
                    container
                    sx={{
                      height: "300px",
                      maxWidth: 800,
                      mt: 2,
                    }}
                  >
                    {/* id document on left and vehicle regostration document on right */}

                    <GridDX
                      item
                      xs={6}
                      sx={{
                        height: "300px",
                        px: 2,
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={visitor.visitorDocuments[0].imageSrc}
                        style={{
                          objectFit: "contain",
                          height: "280px",
                          width: "100%",
                        }}
                      />
                    </GridDX>
                    <GridDX
                      item
                      xs={6}
                      sx={{
                        height: "300px",
                        px: 2,
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={visitor.visitorDocuments[1].imageSrc}
                        style={{
                          objectFit: "contain",
                          height: "280px",
                          width: "100%",
                        }}
                      />
                    </GridDX>
                  </GridDX>

                  <Typography
                    variant="body2"
                    sx={{ textAlign: "center", mt: 2 }}
                  >
                    {t(
                      "Entry permit to the Unified Military Command Headquarters"
                    )}
                  </Typography>
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    )
  );
};

export default AppointmentReport;
