import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Tooltip,
  ListItemIcon,
  Avatar,
  Typography,
} from "@mui/material";
import {
  AccountCircle,
  NotificationsNone as NotificationIcon,
  MoreVert as MoreIcon,
  Menu as MenuIcon,
  Logout,
} from "@mui/icons-material";

import hexmateLogo from "../../../assets/hexmate_logo.png";

import LanguageSwitcher from "../../controls/languageswitcherdx";
import BoxDX from "../../layout/boxdx";

import MobileMenu from "../menu/mobilemenu";
import ProfileModal from "../../editmodals/profilemodal";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/authcontext";
import NotificationMenu from "../notificationmenu";

export default function MainAppBarDX() {
  const { userData } = useAuthContext();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const onProfileClick = () => {
    setOpenProfile(!openProfile);
    handleMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {openProfile && (
        <ProfileModal
          open={openProfile}
          onClose={(reload: any) => {
            setOpenProfile(false);
            reload && window.location.reload();
          }}
        />
      )}
      <MenuItem>
        <LanguageSwitcher color="#bba20b" />
      </MenuItem>
      <MenuItem>
        <IconButton size="large">
          <NotificationIcon />
        </IconButton>
        <p>{t("Notifications")}</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" onClick={onProfileClick}>
          <AccountCircle />
        </IconButton>
        <p>{t("Profile")}</p>
      </MenuItem>
    </Menu>
  );

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <BoxDX sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          backgroundColor: "#406847",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <BoxDX>
            <img
              onClick={() => navigate("/welcome")}
              style={{ cursor: "pointer" }}
              src={hexmateLogo}
              height={50}
            />
          </BoxDX>
          <BoxDX sx={{ flexGrow: 1 }} />
          <BoxDX sx={{ margin: "auto", paddingY: "10px" }}>
            {/* use mui component Typography */}
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: "#fff",
                textAlign: "center",
              }}
            >
              {i18n.language === "en"
                ? userData?.enFullName
                : userData?.arFullName}
            </Typography>
            <Typography
              variant="caption"
              component="div"
              sx={{
                flexGrow: 1,
                color: "#fff",
                textAlign: "center",
              }}
            >
              {i18n.language === "en"
                ? userData?.enJobTitle
                : userData?.arJobTitle}
            </Typography>
          </BoxDX>
          <BoxDX sx={{ flexGrow: 1 }} />
          <BoxDX
            sx={{
              display: { xs: "flex", md: "none", color: "white" },
              alignItems: "center",
            }}
          >
            <IconButton size="large" onClick={toggleMobileMenu} color="inherit">
              <MenuIcon />
            </IconButton>
          </BoxDX>
          <BoxDX
            sx={{
              display: { xs: "none", md: "flex", color: "white" },
              alignItems: "center",
            }}
          >
            <LanguageSwitcher />
            <NotificationMenu />
            <AccountMenu />
          </BoxDX>
          <BoxDX sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon sx={{ color: "#fff" }} />
            </IconButton>
          </BoxDX>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}

      <MobileMenu open={mobileMenuOpen} closeMenu={toggleMobileMenu} />
    </BoxDX>
  );
}

const AccountMenu: React.FC = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signOut } = useAuthContext();

  const [openProfile, setOpenProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => {
    handleClose();
    signOut();
    navigate("/");
  };

  const onProfileClick = () => {
    setOpenProfile(!openProfile);
    handleClose();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Account settings")}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={props.open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={props.open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={onProfileClick}>
          <ListItemIcon>
            <Avatar sx={{ width: 28, height: 28 }} />
          </ListItemIcon>
          {t("Profile")}
        </MenuItem>
        <MenuItem onClick={onLogoutClick}>
          <ListItemIcon sx={{ paddingLeft: 0.5 }}>
            <Logout fontSize="small" sx={{ width: 27, height: 25 }} />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
      {openProfile && (
        <ProfileModal
          open={openProfile}
          onClose={(reload: any) => {
            setOpenProfile(false);
            reload && window.location.reload();
          }}
        />
      )}
    </React.Fragment>
  );
};
