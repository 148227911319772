import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../../context/notificationcontext";
import AddEditModalDX from "../../../business/addeditmodaldx";
import TextFieldDX from "../../../controls/textfielddx";
import GridDX from "../../../layout/griddx";
import Loading from "../../../loading";
import SelectListDX from "../../../controls/selectlistdx";
import TypeTranslator from "../../../../shared/typetranslator";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ButtonDX from "../../../controls/buttondx";
import ListPageDX from "../../../business/listpagedx";
import AddEditVisitorModal from "./addeditvisitormodal";
import DatePickerDX from "../../../controls/datepickerdx";
import moment from "moment";
import {
  handleDateChange,
  AppointmentStatus,
  sortListAlphabetically,
} from "../../../../shared/globals";
import useAppointmentService from "../../../../shared/services/vmsappointmentservice";
import useCountryService from "../../../../shared/services/countryservice";
import useVisitorService from "../../../../shared/services/vmsvisitorservice";
import LoadingButtonDX from "../../../controls/loadingbuttondx";
import ConfirmModal from "../../../controls/confirmmodal";
import AutoCompleteListDX from "../../../controls/autocompletelistdx";
import useWorkflowService from "../../../../shared/services/vmsworkflowservice";

const AppointmentModal = (props: any) => {
  const { handleClose, open, appointmentId } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setError, setInfo } = useNotificationContext();
  const { getAppointmentById, addAppointment, updateAppointment } =
    useAppointmentService();
  const { getCountries } = useCountryService();
  const { deleteVisitor } = useVisitorService();
  const { appointmentTypes, getIdTypeValue, getVehicleTypeValue } =
    TypeTranslator();
  const { getWorkflows } = useWorkflowService();
  const defaultValues = {
    appointmentId: 0,
    appointmentTypeId: 1,
    workflowId: null,
    status: 1,
    organizationName: "",
    visitEndDate: null,
    visitFromDate: null,
    visitLocation: "",
    visitPurpose: "",
    visitors: [],
  };

  const [data, setData] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [countries, setCountries] = useState<any>([]);
  const [workflows, setWorkflows] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    const p1 = appointmentId ? getAppointmentById(appointmentId) : null;
    const p2 = getCountries();
    const p3 = getWorkflows();
    Promise.all([p1, p2, p3])
      .then(([appointment, countries, workflows]) => {
        appointmentId && setData(appointment);
        setCountries(countries);
        setWorkflows(workflows);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.appointmentTypeId)
      newErrors["appointmentTypeId"] = t("Appointment type is required");

    if (data.organizationName.length === 0)
      newErrors["organizationName"] = t("Organization name is required");

    if (!data.workflowId) newErrors["workflowId"] = t("Workflow is required");

    if (data.visitPurpose.length === 0)
      newErrors["visitPurpose"] = t("Visit purpose is required");

    if (!data.visitEndDate)
      newErrors["visitEndDate"] = t("Visit end date is required");

    if (!data.visitFromDate)
      newErrors["visitFromDate"] = t("Visit start date is required");

    if (data.visitLocation.length === 0)
      newErrors["visitLocation"] = t("Visit location is required");
    if (data.visitors.length === 0) {
      setError(t("Atleast one visitor is required"));
      return false;
    }
    // we have to make sure all visitors have 2 documents each (either file or downloadURL)
    const hasError = data.visitors.some((visitor: any) => {
      console.log("visitor", visitor);
      return visitor.visitorDocuments.some(
        (doc: any) => !doc.file && !doc.downloadURL
      );
    });
    if (hasError) {
      setError(t("All visitors must have all documents"));
      return false;
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const onSave = (status: AppointmentStatus) => {
    if (!validateForm()) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("appointmentTypeId", `${data.appointmentTypeId}`);
    formData.append("Status", `${status}`);
    formData.append("workflowId", `${data.workflowId}`);
    formData.append("organizationName", data.organizationName);
    formData.append("visitFromDate", `${data.visitFromDate}`);
    formData.append("visitEndDate", `${data.visitEndDate}`);
    formData.append("visitLocation", data.visitLocation);
    formData.append("visitPurpose", data.visitPurpose);

    data.visitors.forEach((visitor: any, index: number) => {
      // we have to append the visitor data to the form data one by one using index

      if (visitor.visitorId.toString().includes("temp")) {
        formData.append(`visitors[${index}].visitorId`, "0");
      } else {
        formData.append(`visitors[${index}].visitorId`, `${visitor.visitorId}`);
      }
      `visitors[${index}].appointmentId` &&
        formData.append(
          `visitors[${index}].appointmentId`,
          `${visitor.appointmentId}`
        );
      formData.append(
        `visitors[${index}].visitorTypeId`,
        `${visitor.visitorTypeId}`
      );
      formData.append(
        `visitors[${index}].namePrefixId`,
        `${visitor.namePrefixId}`
      );
      formData.append(`visitors[${index}].name`, visitor.name);
      formData.append(`visitors[${index}].countryId`, `${visitor.countryId}`);
      formData.append(
        `visitors[${index}].contactTypeId`,
        `${visitor.contactTypeId}`
      );
      formData.append(
        `visitors[${index}].contactNumber`,
        visitor.contactNumber
      );
      formData.append(
        `visitors[${index}].vehicleRegistrationNumber`,
        visitor.vehicleRegistrationNumber
      );
      formData.append(`visitors[${index}].vehicleColor`, visitor.vehicleColor);
      formData.append(`visitors[${index}].idTypeId`, `${visitor.idTypeId}`);
      formData.append(`visitors[${index}].idDetails`, visitor.idDetails);
      if (visitor.visitorDocuments[0].file) {
        formData.append(
          `visitors[${index}].visitorDocuments[0].visitorId`,
          `${visitor.visitorDocuments[0].visitorId}`
        );
        formData.append(
          `visitors[${index}].visitorDocuments[0].documentTypeId`,
          `${visitor.visitorDocuments[0].documentTypeId}`
        );
        formData.append(
          `visitors[${index}].visitorDocuments[0].file`,
          visitor.visitorDocuments[0].file
        );
      }
      if (visitor.visitorDocuments[1].file) {
        if (!visitor.visitorDocuments[0].file) {
          formData.append(
            `visitors[${index}].visitorDocuments[0].visitorId`,
            `${visitor.visitorDocuments[1].visitorId}`
          );
          formData.append(
            `visitors[${index}].visitorDocuments[0].documentTypeId`,
            `${visitor.visitorDocuments[1].documentTypeId}`
          );
          formData.append(
            `visitors[${index}].visitorDocuments[0].file`,
            visitor.visitorDocuments[1].file
          );
        } else {
          formData.append(
            `visitors[${index}].visitorDocuments[1].visitorId`,
            `${visitor.visitorDocuments[1].visitorId}`
          );
          formData.append(
            `visitors[${index}].visitorDocuments[1].documentTypeId`,
            `${visitor.visitorDocuments[1].documentTypeId}`
          );
          formData.append(
            `visitors[${index}].visitorDocuments[1].file`,
            visitor.visitorDocuments[1].file
          );
        }
      }
    });

    if (data.appointmentId)
      formData.append("appointmentId", `${data.appointmentId}`);

    const operation = appointmentId ? updateAppointment : addAppointment;
    operation(formData)
      .then(() => {
        appointmentId
          ? setInfo(t("Appointment updated successfully"))
          : setInfo(t("Appointment added successfully"));
        handleClose(true);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const visitorColumns = [
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
    },
    {
      field: "countryId",
      headerName: t("Country"),
      flex: 1,
      valueGetter: (params: any) => {
        const country = countries.find(
          (c: any) => c.countryId === params.value
        );
        return i18n.language === "en"
          ? country?.enName
          : country?.arName || country?.enName;
      },
    },
    {
      field: "contactNumber",
      headerName: t("Contact Number"),
      flex: 1,
    },
    {
      field: "vehicleRegistrationNumber",
      headerName: t("Vehicle Registration Number"),
      flex: 1,
    },
    {
      field: "vehicleColor",
      headerName: t("Vehicle Color"),
      flex: 1,
    },
  ];
  const [showVisitorModal, setShowVisitorModal] = useState(false);
  const [visitorId, setVisitorId] = useState(null);

  const handleCloseVisitorModal = () => {
    setVisitorId(null);
    setShowVisitorModal(false);
  };
  const onEditVisitor = (visitor: any) => {
    console.log(visitor);
    setVisitorId(visitor.visitorId);
    setShowVisitorModal(true);
  };
  const onDeleteVisitor = (visitorId: any) => {
    deleteVisitor(visitorId)
      .then(() => {
        const newVisitors = data.visitors.filter(
          (x: any) => x.visitorId !== visitorId
        );
        setData({
          ...data,
          visitors: newVisitors,
        });
      })
      .catch((err) => {
        setError(err);
      });
  };

  const [openConfirmationModal, SetOpenConfirmationModal] = useState(false);

  const onClose = () => {
    if (isChanged) SetOpenConfirmationModal(true);
    else props.handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"xl"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {appointmentId ? t("Edit Appointment") : t("Create Appointment")}
      </DialogTitle>
      <DialogContent>
        {openConfirmationModal && (
          <ConfirmModal
            open={openConfirmationModal}
            onYesClick={props.handleClose}
            onNoClick={() => SetOpenConfirmationModal(false)}
          />
        )}
        {isLoading && (
          <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
        )}
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
          {showVisitorModal && (
            <AddEditVisitorModal
              open={showVisitorModal}
              handleClose={handleCloseVisitorModal}
              appointmentData={data}
              setAppointmentData={setData}
              visitorId={visitorId}
              countries={countries}
            />
          )}
          <GridDX item xs={12}>
            <SelectListDX
              label={`${t("Appointment Type")}`}
              items={appointmentTypes}
              name="appointmentTypeId"
              value={data.appointmentTypeId}
              onChange={handleInputChange}
              error={errors["appointmentTypeId"]}
              InputLabelProps={{
                shrink: data.appointmentTypeId !== null,
              }}
            />
          </GridDX>

          <GridDX item xs={12} md={6}>
            <TextFieldDX
              label={t("Organization Name")}
              name="organizationName"
              value={data.organizationName}
              onChange={handleInputChange}
              error={errors["organizationName"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <AutoCompleteListDX
              label={t("Workflow")}
              list={sortListAlphabetically(
                workflows.map((x: any) => ({
                  value: x.workflowId,
                  text: languageIsEn ? x.enName : x.arName,
                }))
              )}
              name="workflowId"
              value={data.workflowId}
              onChange={handleInputChange}
              error={errors["workflowId"]}
              InputLabelProps={{
                shrink: data.workflowId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>

          <GridDX item xs={12} md={6}>
            <DatePickerDX
              label={t("From")}
              sx={{ flex: 1 }}
              name="visitFromDate"
              value={moment(data.visitFromDate)}
              error={errors["visitFromDate"]}
              onChange={(value: any) => {
                handleDateChange({
                  value: value,
                  name: "visitFromDate",
                  setIsChanged: setIsChanged,
                  setData: setData,
                  data: data,
                });
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <DatePickerDX
              label={t("To")}
              sx={{ flex: 1 }}
              name="visitEndDate"
              value={moment(data.visitEndDate)}
              error={errors["visitEndDate"]}
              onChange={(value: any) => {
                handleDateChange({
                  value: value,
                  name: "visitEndDate",
                  setIsChanged: setIsChanged,
                  setData: setData,
                  data: data,
                });
              }}
              minDate={moment(data.visitFromDate).add(0, "days")}
            />
          </GridDX>

          <GridDX item xs={12} md={6}>
            <TextFieldDX
              label={`${t("Visit Location")}`}
              name="visitLocation"
              value={data.visitLocation}
              onChange={handleInputChange}
              error={errors["visitLocation"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              label={`${t("Visit Purpose")}`}
              name="visitPurpose"
              value={data.visitPurpose}
              onChange={handleInputChange}
              error={errors["visitPurpose"]}
            />
          </GridDX>

          <GridDX
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h6" component="label" sx={{ height: 32 }}>
              {t("Visitors")}
            </Typography>

            {(data.appointmentTypeId === 1 && data.visitors.length != 1) ||
            data.appointmentTypeId === 2 ? (
              <ButtonDX onClick={() => setShowVisitorModal(true)}>
                {t("Add Visitor")}
              </ButtonDX>
            ) : null}
          </GridDX>
          <ListPageDX
            listTitle={t("Visitors")}
            name={t("visitor")}
            rows={data.visitors}
            columns={visitorColumns}
            getRowId={(row: any) => row.visitorId}
            isLoading={isLoading}
            exportToPDF={false}
            exportToCSV={false}
            searchAndFilter={false}
            onEdit={onEditVisitor}
            onDelete={onDeleteVisitor}
            rowsPerPage={data.visitors.length}
            hideFooter={true}
          />
        </GridDX>
      </DialogContent>
      <DialogActions>
        <GridDX
          container
          width={"100%"}
          sx={{ justifyContent: "space-around" }}
        >
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <ButtonDX
              variant="outlined"
              onClick={onClose}
              sx={{ mb: { xs: 2, sm: "auto" } }}
            >
              {t("Cancel")}
            </ButtonDX>
          </GridDX>
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <LoadingButtonDX
              onClick={() => onSave(AppointmentStatus.Draft)}
              loading={isLoading}
            >
              {t("Save as Draft")}
            </LoadingButtonDX>
          </GridDX>
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <LoadingButtonDX
              onClick={() => onSave(AppointmentStatus.Pending)}
              loading={isLoading}
            >
              {t("Save and Send")}
            </LoadingButtonDX>
          </GridDX>
        </GridDX>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentModal;
