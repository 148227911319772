import { Typography, LinearProgress, Breadcrumbs } from "@mui/material";
import GridSearchFilterTextbox from "../../business/gridsearchfiltertextbox";
import ButtonDX from "../../controls/buttondx";
import MenuButtonDX from "../../controls/menubutton";
import MenuItemDX from "../../controls/menuitemdx";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import { useEffect, useState } from "react";
import DirectoryModal from "../../editmodals/DMS/directorymodal";
import { useTranslation } from "react-i18next";
import MoveModal from "../../editmodals/DMS/movemodal";
import CopyModal from "../../editmodals/DMS/copymodal";
import DeleteModal from "../../controls/deleteModal";
import DocumentUploadModal from "../../editmodals/DMS/documentuploadmodal";
import useFolderService from "../../../shared/services/folderservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import useDocumentService from "../../../shared/services/documentservice";
import ContentView from "./contentview";
import MenuDX from "../../controls/menudx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import customerLogo from "../../../assets/customer_logo.png";
import DocumentScanModal from "../../editmodals/DMS/documentscanmodal";
import { mkConfig, generateCsv, download } from "export-to-csv";
import AdvancedSearchModal from "../../editmodals/DMS/advancedsearchmodal";
import useCabinetService from "../../../shared/services/cabinetservice";
import { useAuthContext } from "../../../context/authcontext";
import CabinetModal from "../../editmodals/DMS/cabinetModal";

const FileExplorer = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setError, setInfo } = useNotificationContext();
  const { getArchivedContentByCabinetID } = useCabinetService();
  const {
    getFolderContent,
    bulkDeleteFolder,
    getFolderTreeByCabinet,
    bulkMoveFolder,
    bulkCopyFolder,
  } = useFolderService();
  const { bulkDeleteDocument, bulkMoveDocument, bulkCopyDocument } =
    useDocumentService();
  const { userData } = useAuthContext();

  const [directory, setDirectory] = useState<any>(null);
  const [checkedFolders, setCheckedFolders] = useState<any>([]);
  const [checkedFiles, setCheckedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<any>({
    open: false,
    id: null,
    document: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openDirectoryModal, setOpenDirectoryModal] = useState(false);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [openScanModal, setOpenScanModal] = useState(false);
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [tree, setTree] = useState<any>();
  const [directoryContent, setDirectoryContent] = useState<any>(null);
  const [treeForCabinet, setTreeForCabinet] = useState<any>();
  const [selectedSortIndex, setSelectedSortIndex] = useState<null | number>(
    null
  );
  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const [userCanManageCabinet, setUserCanManageCabinet] = useState(false);
  const [openManageCabinetModal, setOpenManageCabinetModal] = useState(false);

  useEffect(() => {
    getData();
    handleSort("reset");
  }, [props.folder]);

  useEffect(() => {
    if (props.tree) setTree(props.tree);
  }, [props.tree]);

  useEffect(() => {
    if (directory) setDirectoryContent(directory);
  }, [directory]);

  useEffect(() => {
    getTreeForCabinet();

    if (userData.isAdmin) setUserCanManageCabinet(true);
    else {
      const userFound = props.cabinet?.cabinetManagers.find(
        (u: any) => u.managerUserId === userData.userId
      )
        ? true
        : false;

      setUserCanManageCabinet(userFound);
    }
  }, [props.cabinet]);

  const getData = async () => {
    setTree(null);
    setDirectoryContent(null);
    if (props.folder) {
      setIsLoading(true);
      getFolderContent(props.folder.folderId)
        .then((content: any) => {
          setDirectory(content);
        })
        .catch((err: any) => setError(err))
        .finally(() => setIsLoading(false));
    } else if (props.cabinet) {
      setIsLoading(true);
      getFolderTreeByCabinet(props.cabinet?.cabinetId)
        .then((res: any) => {
          props.onCabinetRefresh(props.cabinet, res);
          setTree(res);
        })
        .catch((err: any) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const getTrash = async (cabinetId: number) => {
    setIsLoading(true);
    getArchivedContentByCabinetID(cabinetId)
      .then((res: any) => {
        props.setActiveTrash(res);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const handleToggle = (value: number, type: string) => {
    const currentIndex =
      type === "file"
        ? checkedFiles.indexOf(value)
        : checkedFolders.indexOf(value);
    const newChecked =
      type === "file" ? [...checkedFiles] : [...checkedFolders];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (type === "file") setCheckedFiles(newChecked);
    else setCheckedFolders(newChecked);
  };

  const toDelete = () => {
    setIsLoading(true);
    const p1 =
      checkedFiles.length > 0 ? bulkDeleteDocument(checkedFiles) : null;
    const p2 =
      checkedFolders.length > 0 ? bulkDeleteFolder(checkedFolders) : null;
    Promise.all([p1, p2])
      .then((content: any) => {
        setInfo(t("Selected items deleted successfully"));
        onClose(true);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onClose = (refreshPage: boolean = false) => {
    setDeleteModal({ open: false });
    setOpenDirectoryModal(false);
    setOpenMoveModal(false);
    setOpenCopyModal(false);
    setOpenScanModal(false);
    setOpenFileUploadModal(false);
    setOpenManageCabinetModal(false);

    if (refreshPage) {
      getData();
      getTreeForCabinet();
    }
  };

  const handleExportToPDF = () => {
    if (
      props.folder === null &&
      props.shared === null &&
      props.tree?.length > 0
    ) {
      const tableHeading =
        i18n.language === "en" ? props.cabinet?.enName : props.cabinet?.arName;
      getTablePDF(props.tree, [], tableHeading);
    } else if (props.folder && directory) {
      console.log(directory.folders, directory.documents);
      const tableHeading =
        i18n.language === "en" ? directory?.enName : directory?.arName;
      getTablePDF(directory.folders, directory.documents, tableHeading);
    } else if (props.shared) {
      const tableHeading = t("Shared with me");
      getTablePDF(
        props.shared?.sharedFolders,
        props.shared?.sharedDocuments,
        tableHeading
      );
    }
  };

  // Function to handle PDF export
  const getTablePDF = async (folders: any, documents: any, title: string) => {
    // Create a new jsPDF instance
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: "a4",
    });
    // Define the columns and rows for the PDF table
    let folder_column = [t("Folder Name")];
    let folder_rows = folders.map((row: any) =>
      i18n.language === "en" ? [row.enName] : [row.arName]
    );

    let document_column = [t("Document Name")];
    let document_rows = documents.map((row: any) => [row.enName]);

    // changing alignment of data fields for arabic pdf

    if (i18n.language !== "en") {
      // if language is arabic
      folder_column = folder_column.reverse();
      document_column = document_column.reverse();
    }

    const totalPages = Math.ceil(
      folder_rows.length / 15 + document_rows.length / 15
    ); // You might need to adjust the number 20 to control the number of rows per page.
    doc.setFont("arabic-font", "normal");

    for (let page = 1; page <= totalPages; page++) {
      if (page > 1) {
        doc.addPage();
      }

      // Define the rows for the current page
      const start = (page - 1) * 15;
      const end = page * 15;
      let currentFolderPageRows = folder_rows.slice(start, end);
      let currentDocumentPageRows = document_rows.slice(start, end);
      if (i18n.language !== "en") {
        // if language is arabic
        currentFolderPageRows = currentFolderPageRows.map((e: any[]) =>
          e.reverse()
        );
        currentDocumentPageRows = currentDocumentPageRows.map((e: any[]) =>
          e.reverse()
        );
      }

      // Set the header image at the top left
      doc.addImage(customerLogo, "JPEG", 5, 1, 100, 60); // Adjust the image dimensions and position as needed
      // Set the header text (heading for the table)
      const headingX =
        i18n.language !== "en" ? doc.internal.pageSize.width - 10 : 10;
      const headingY = 60; // Adjust the vertical position as needed
      doc.setFontSize(20); // Adjust font size as needed
      doc.text(
        title,
        headingX,
        headingY,
        undefined,
        i18n.language !== "en" ? "right" : "left"
      );

      // Add a table to the PDF document using jspdf-autotable
      if (folder_rows.length > 0) {
        autoTable(doc, {
          head: [folder_column],
          body: currentFolderPageRows,
          styles: {
            font: i18n.language !== "en" ? "arabic-font" : undefined,
            halign: i18n.language !== "en" ? "right" : undefined,
            cellWidth: "wrap",
          },
          columnStyles: {},
          margin: { top: 70, left: 10, right: 10 },
          horizontalPageBreak: true,
        });
      }

      if (document_rows.length > 0) {
        autoTable(doc, {
          head: [document_column],
          body: currentDocumentPageRows,
          styles: {
            font: i18n.language !== "en" ? "arabic-font" : undefined,
            halign: i18n.language !== "en" ? "right" : undefined,
            cellWidth: "wrap",
          },
          columnStyles: {},
          margin: { top: 70, left: 10, right: 10 },
          horizontalPageBreak: true,
        });
      }

      // Draw a horizontal line
      doc.setLineWidth(0.5); // Adjust line width as needed
      doc.line(
        10,
        doc.internal.pageSize.height - 20,
        doc.internal.pageSize.width - 10,
        doc.internal.pageSize.height - 20
      );

      // Set the footer text (left side: "Customer Name " and right side: printed date)
      const footerLeftText = "Customer Name";
      const footerRightText = new Date().toLocaleDateString();
      const footerXLeft = 10;
      const footerXRight = doc.internal.pageSize.width - 10;
      const footerY = doc.internal.pageSize.height - 10;
      doc.setFontSize(10); // Adjust font size as needed
      doc.text(footerLeftText, footerXLeft, footerY);
      doc.text(footerRightText, footerXRight, footerY, undefined, "right");
    }

    //   // To open the PDF in a new tab:
    // doc.output("dataurlnewwindow");
    //   // To save the PDF as a file:
    doc.save(`CustomerName_${title}.pdf`);
  };

  const handleSort = (sortBy: string, increasingOrder = true) => {
    if (sortBy === "reset") {
      setSelectedSortIndex(null);
      setTree(props.tree);
      setDirectoryContent(directory);
      setCheckedFiles([]);
      setCheckedFolders([]);
      return;
    }
    setSelectedSortIndex(
      sortBy === "name" ? 0 : sortBy === "createdDate" ? 1 : 2
    );
    const sortFunction = (a: any, b: any) => {
      if (sortBy === "name") {
        return increasingOrder
          ? languageIsEn
            ? a.enName.localeCompare(b.enName)
            : a.arName.localeCompare(b.arName)
          : languageIsEn
          ? b.enName.localeCompare(a.enName)
          : b.arName.localeCompare(a.arName);
      } else if (sortBy === "createdDate" || sortBy === "lastUpdatedDate") {
        const dateA = new Date(
          sortBy === "createdDate" ? a.createdDate : a.modifiedDate
        );
        const dateB = new Date(
          sortBy === "createdDate" ? b.createdDate : b.modifiedDate
        );
        return increasingOrder
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else {
        // Handle default or fallback sorting
        return increasingOrder
          ? languageIsEn
            ? a.enName.localeCompare(b.enName)
            : a.arName.localeCompare(b.arName)
          : languageIsEn
          ? b.enName.localeCompare(a.enName)
          : b.arName.localeCompare(a.arName);
      }
    };
    if (props.folder && directory) {
      setDirectoryContent((directoryContent: any) => {
        let newDirectoryContent = JSON.parse(JSON.stringify(directoryContent));
        // @ts-ignore
        newDirectoryContent?.documents.sort(sortFunction);
        // @ts-ignore
        newDirectoryContent?.folders.sort(sortFunction);
        return newDirectoryContent;
      });
    } else if (
      props.folder === null &&
      props.trash === null &&
      props.shared === null &&
      props.tree?.length > 0
    ) {
      setTree((tree: any) => {
        let newtree = JSON.parse(JSON.stringify(tree));
        newtree.sort(sortFunction);
        return newtree;
      });
    }
  };

  const handleSearch = (searchText: string) => {
    const filterItems = (items: any[], isDocument: boolean = false) => {
      return items.filter(
        (item) =>
          (languageIsEn
            ? item.enName.toLowerCase().includes(searchText.toLowerCase())
            : item.arName.includes(searchText)) ||
          (isDocument
            ? (item.contentAsText ?? "")
                .toLowerCase()
                .includes(searchText.toLowerCase())
            : false)
      );
    };

    if (searchText === "") {
      setTree(props.tree);
      setDirectoryContent(directory);
      return;
    }

    if (
      props.folder === null &&
      props.trash === null &&
      props.shared === null
    ) {
      const searchResult = filterItems(props.tree || []);
      setTree(searchResult);
    } else if (props.folder && directory) {
      const filteredDocuments = filterItems(directory.documents || [], true);
      const filteredFolders = filterItems(directory.folders || []);
      setDirectoryContent({
        ...directory,
        documents: filteredDocuments,
        folders: filteredFolders,
      });
    }
  };

  const reverseSortingOrder = () => {
    if (selectedSortIndex === 0 || selectedSortIndex === null)
      handleSort("name", !isAscending);
    else if (selectedSortIndex === 1) handleSort("createdDate", !isAscending);
    else if (selectedSortIndex === 2)
      handleSort("lastUpdatedDate", !isAscending);
    setIsAscending((prev) => !prev);
  };

  function findRelativePath(folders: any, targetFolderId: any, path = ""): any {
    for (const folder of folders) {
      if (folder.folderId === targetFolderId) {
        path = path + `${folder.folderId},${folder.enName},${folder.arName}/`;
        const object = path.split("/");
        return object;
      }
      if (folder.folders.length > 0) {
        const result: any = findRelativePath(
          folder.folders,
          targetFolderId,
          path + `${folder.folderId},${folder.enName},${folder.arName}/`
        );
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const toMove = () => {
    if (checkedFiles.length > 0 && !selectedFolder?.folderId) {
      setError("Documents cannot be moved, please select a folder");
      return;
    }

    const fileData = {
      documentIds: checkedFiles,
      newFolderId: selectedFolder.folderId,
    };
    const folderData = {
      folderIds: checkedFolders,
      newParentFolderId: selectedFolder?.folderId || -1,
      newCabinetId:
        props.cabinet?.cabinetId === selectedFolder?.cabinetId
          ? -1
          : selectedFolder?.cabinetId,
    };

    setIsLoading(true);
    const p1 = checkedFiles.length > 0 ? bulkMoveDocument(fileData) : null;
    const p2 = checkedFolders.length > 0 ? bulkMoveFolder(folderData) : null;
    Promise.all([p1, p2])
      .then((res: any) => {
        setInfo(t("Contents moved successfully"));
        setCheckedFiles([]);
        setCheckedFolders([]);
        onClose(true);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCopy = () => {
    if (checkedFiles.length > 0 && !selectedFolder?.folderId) {
      setError("Documents cannot be copied, please select a folder");
      return;
    }

    const fileData = {
      documentIds: checkedFiles,
      newFolderId: selectedFolder.folderId,
    };
    const folderData = {
      folderIds: checkedFolders,
      newParentFolderId: selectedFolder?.folderId || -1,
      newCabinetId:
        props.cabinet?.cabinetId === selectedFolder?.cabinetId
          ? -1
          : selectedFolder?.cabinetId,
    };

    setIsLoading(true);
    const p1 = checkedFiles.length > 0 ? bulkCopyDocument(fileData) : null;
    const p2 = checkedFolders.length > 0 ? bulkCopyFolder(folderData) : null;
    Promise.all([p1, p2])
      .then((res: any) => {
        setInfo(t("Contents copied successfully"));
        setCheckedFiles([]);
        setCheckedFolders([]);
        onClose(true);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const getTreeForCabinet = async () => {
    if (props.cabinet) {
      setIsLoading(true);
      getFolderTreeByCabinet(props.cabinet?.cabinetId)
        .then((res: any) => {
          setTreeForCabinet(res);
        })
        .catch((err: any) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const handleExportToCSV = async () => {
    const nameKey = t("Name");
    if (
      props.folder === null &&
      props.shared === null &&
      props.tree?.length > 0
    ) {
      const FileName =
        t("DMS Reports") +
        "-" +
        `{${t("Cabinet")}_${
          i18n.language === "en" ? props.cabinet?.enName : props.cabinet?.arName
        }"}`;
      const csvConfig = mkConfig({
        filename: FileName,
        useKeysAsHeaders: true,
      });
      const data = tree.map((item: any) => {
        return {
          [nameKey]: languageIsEn
            ? `[${t("Directory")}] ` + item.enName
            : `[${t("Directory")}] ` + item.arName,
        };
      });

      const csv = generateCsv(csvConfig)(data);
      download(csvConfig)(csv);
    } else if (props.folder && directory) {
      const FileName =
        t("DMS Reports") +
        "-" +
        `{${t("Directory")}_${
          i18n.language === "en" ? directory?.enName : directory?.arName
        }"}`;
      const csvConfig = mkConfig({
        filename: FileName,
        useKeysAsHeaders: true,
      });

      let data = directory.folders.map((item: any) => {
        return {
          [nameKey]: languageIsEn
            ? `[${t("Directory")}] ` + item.enName
            : `[${t("Directory")}] ` + item.arName,
        };
      });
      data = data.concat(
        directory.documents.map((item: any) => {
          return {
            [nameKey]: languageIsEn
              ? `[${t("Document")}] ` + item.enName
              : `[${t("Document")}] ` + item.arName,
          };
        })
      );
      const csv = generateCsv(csvConfig)(data);
      download(csvConfig)(csv);
    }
  };

  return (
    <>
      {openDirectoryModal && (
        <DirectoryModal
          open={openDirectoryModal}
          handleClose={onClose}
          cabinetId={props.cabinet?.cabinetId}
          folderId={props.folder?.folderId}
          directory={null}
        />
      )}
      {openFileUploadModal && (
        <DocumentUploadModal
          open={openFileUploadModal}
          handleClose={onClose}
          folderId={props.folder?.folderId}
        />
      )}
      {openMoveModal && (
        <MoveModal
          open={openMoveModal}
          handleClose={onClose}
          folder={selectedFolder}
          onFolderSelect={setSelectedFolder}
          toMove={toMove}
        />
      )}
      {openCopyModal && (
        <CopyModal
          open={openCopyModal}
          handleClose={onClose}
          folder={selectedFolder}
          onFolderSelect={setSelectedFolder}
          toCopy={toCopy}
        />
      )}
      {openSearchModal && (
        <AdvancedSearchModal
          open={openSearchModal}
          toClose={() => setOpenSearchModal(false)}
        />
      )}
      {openScanModal && (
        <DocumentScanModal
          open={openScanModal}
          onClose={onClose}
          folderId={props.folder?.folderId}
        />
      )}
      {deleteModal.open && (
        <DeleteModal
          open={deleteModal.open}
          onDeleteClick={toDelete}
          onCancelClick={() => setDeleteModal({ open: false })}
          deleteMessage={t(
            "Are you sure you want to delete the selected items?"
          )}
        />
      )}
      {openManageCabinetModal && (
        <CabinetModal
          open={openManageCabinetModal}
          handleClose={onClose}
          cabinetId={props.cabinet.cabinetId}
          onlyManageUsers={true}
        />
      )}
      <GridDX
        item
        xs={10}
        sx={{
          overflowX: "hidden",
          height: "90%",
          flexDirection: "column",
        }}
      >
        <BoxDX
          sx={{
            minHeight: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #dcdcdc",
          }}
        >
          <BoxDX
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {props.folder === null &&
              props.trash === null &&
              props.shared === null &&
              props.tree?.length > 0 && (
                <ButtonDX
                  variant="text"
                  disabled
                  sx={{
                    "&:disabled": {
                      color: "black",
                    },
                  }}
                >
                  {languageIsEn ? props.cabinet?.enName : props.cabinet?.arName}
                </ButtonDX>
              )}
            {props.trash && (
              <Typography fontWeight={"bold"} sx={{ mx: 2 }}>
                {`${
                  languageIsEn ? props.cabinet?.enName : props.cabinet?.arName
                }
                / ${t("Trash")}`}
              </Typography>
            )}
            {props.shared && (
              <Typography fontWeight={"bold"} sx={{ mx: 2 }}>
                {t("Shared with me")}
              </Typography>
            )}
            {props.folder && directory && (
              <Breadcrumbs sx={{ width: "100%" }}>
                {findRelativePath(
                  treeForCabinet || [],
                  props.folder?.folderId,
                  `0,${props.cabinet?.enName},${props.cabinet?.arName}/`
                ).map((folder: any, index: number) => {
                  if (folder !== "")
                    return (
                      <ButtonDX
                        variant="text"
                        onClick={() => {
                          const id = parseInt(folder.split(",")[0]);
                          props.onFolderChange(
                            id > 0
                              ? {
                                  folderId: id,
                                }
                              : null
                          );
                        }}
                        fullWidth
                        disabled={
                          directory.folderId === parseInt(folder.split(",")[0])
                        }
                        key={index.toString()}
                        sx={{
                          "&:disabled": {
                            color: "black",
                          },
                        }}
                      >
                        {languageIsEn
                          ? folder.split(",")[1]
                          : folder.split(",")[2]}
                      </ButtonDX>
                    );
                  else return [];
                })}
              </Breadcrumbs>
            )}
          </BoxDX>
          <BoxDX
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {userCanManageCabinet && (
              <ButtonDX
                sx={{ minWidth: 200, ml: 2 }}
                onClick={() => setOpenManageCabinetModal(true)}
              >
                {t("Manage Users")}
              </ButtonDX>
            )}
            <ButtonDX
              sx={{ minWidth: 200, mx: 2 }}
              onClick={() => setOpenSearchModal(true)}
            >
              {t("Advanced Search")}
            </ButtonDX>
            {props.folder && (
              <ButtonDX
                sx={{ maxWidth: 100, mx: 2 }}
                onClick={() => {
                  props.folder.parentFolderId
                    ? props.onFolderChange({
                        folderId: props.folder.parentFolderId,
                      })
                    : props.onFolderChange(null);
                }}
              >
                {t("Up")}
              </ButtonDX>
            )}
            {props.trash === null && (
              <MenuButtonDX
                label={t("New")}
                sx={
                  languageIsEn ? { mr: 2, width: 100 } : { ml: 2, width: 100 }
                }
              >
                <MenuItemDX
                  onClick={() => {
                    setOpenDirectoryModal(true);
                  }}
                >
                  {t("Directory")}
                </MenuItemDX>
                {props.folder && (
                  <MenuItemDX onClick={() => setOpenFileUploadModal(true)}>
                    {t("Upload")}
                  </MenuItemDX>
                )}
                {props.folder && (
                  <MenuItemDX onClick={() => setOpenScanModal(true)}>
                    {t("Scan")}
                  </MenuItemDX>
                )}
              </MenuButtonDX>
            )}
          </BoxDX>
        </BoxDX>
        {!props.trash && (
          <BoxDX
            sx={{
              height: 60,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #dcdcdc",
              backgroundColor: "#dcdcdc",
            }}
          >
            <MenuButtonDX sx={{ mx: 2, width: 100 }} label={t("Select")}>
              <MenuItemDX
                onClick={() => {
                  let fileIds = [];
                  let folderIds = [];
                  if (props.folder && directory) {
                    for (let item of directory.folders)
                      folderIds.push(item.folderId);
                    for (let item of directory.documents)
                      fileIds.push(item.documentId);
                  } else {
                    for (let item of props.tree) folderIds.push(item.folderId);
                  }
                  setCheckedFiles(fileIds);
                  setCheckedFolders(folderIds);
                }}
              >
                {t("All")}
              </MenuItemDX>
              <MenuItemDX
                onClick={() => {
                  setCheckedFiles([]);
                  setCheckedFolders([]);
                }}
              >
                {t("None")}
              </MenuItemDX>
            </MenuButtonDX>
            {checkedFiles.length + checkedFolders.length > 0 && (
              <MenuButtonDX
                icon={<TuneOutlinedIcon />}
                sx={languageIsEn ? { mr: 2, width: 50 } : { ml: 2, width: 50 }}
              >
                <MenuItemDX onClick={() => setOpenCopyModal(true)}>
                  {t("Copy")}
                </MenuItemDX>
                <MenuItemDX onClick={() => setOpenMoveModal(true)}>
                  {t("Move")}
                </MenuItemDX>
                <MenuItemDX
                  onClick={() => {
                    setDeleteModal({ open: true });
                  }}
                >
                  {t("Delete")}
                </MenuItemDX>
              </MenuButtonDX>
            )}
            <GridSearchFilterTextbox
              sx={
                languageIsEn
                  ? { mr: 2, maxWidth: 600 }
                  : { ml: 2, maxWidth: 600 }
              }
              setGridFilterCriteria={handleSearch}
            />
            <MenuDX
              sx={{ minWidth: 200 }}
              label={t("Sort")}
              selectedIndex={selectedSortIndex}
              options={[
                { label: t("Name"), onclick: () => handleSort("name") },
                {
                  label: t("Creation Date"),
                  onclick: () => handleSort("createdDate"),
                },
                {
                  label: t("Last Updated Date"),
                  onclick: () => handleSort("lastUpdatedDate"),
                },
                // { label: t("Reset"), onclick: () => handleSort("reset") },
              ]}
            />
            <ButtonDX sx={{ mx: 2, width: 10 }} onClick={reverseSortingOrder}>
              <ImportExportOutlinedIcon />
            </ButtonDX>
            <MenuButtonDX label={t("Export")}>
              <MenuItemDX onClick={handleExportToPDF}>{t("PDF")}</MenuItemDX>
              <MenuItemDX onClick={handleExportToCSV}>{t("CSV")}</MenuItemDX>
            </MenuButtonDX>
            <ButtonDX
              sx={{ maxWidth: 100, mx: 2 }}
              onClick={() => handleSort("reset")}
            >
              {t("Reset")}
            </ButtonDX>
          </BoxDX>
        )}
        {isLoading && <LinearProgress />}
        {props.folder === null &&
          props.trash === null &&
          props.shared === null &&
          props.tree?.length > 0 && (
            <ContentView
              folders={tree || props.tree}
              documents={[]}
              checkedFolders={checkedFolders}
              checkedFiles={checkedFiles}
              cabinetId={props.cabinet?.cabinetId}
              folderId={props.folder?.folderId}
              toSelect={handleToggle}
              toRefresh={getData}
              onFolderChange={props.onFolderChange}
            />
          )}
        {props.trash && (
          <ContentView
            folders={props.trash?.folders}
            documents={props.trash?.documents}
            checkedFolders={checkedFolders}
            checkedFiles={checkedFiles}
            toSelect={handleToggle}
            toRefresh={() => {
              getTrash(props.cabinet?.cabinetId);
            }}
            isTrash={true}
            onFolderChange={props.onFolderChange}
          />
        )}
        {props.shared && (
          <ContentView
            folders={props.shared?.sharedFolders}
            documents={props.shared?.sharedDocuments}
            checkedFolders={checkedFolders}
            checkedFiles={checkedFiles}
            toSelect={handleToggle}
            toRefresh={getData}
            onFolderChange={props.onFolderChange}
          />
        )}
        {props.folder && directory && (
          <ContentView
            folders={directoryContent?.folders || directory.folders}
            documents={directoryContent?.documents || directory.documents}
            cabinetId={props.cabinet?.cabinetId}
            folderId={props.folder?.folderId}
            checkedFolders={checkedFolders}
            checkedFiles={checkedFiles}
            toSelect={handleToggle}
            toRefresh={getData}
            onFolderChange={props.onFolderChange}
          />
        )}
      </GridDX>
    </>
  );
};

export default FileExplorer;
