import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import {
  AssignmentIndOutlined,
  HourglassBottomOutlined,
} from "@mui/icons-material";
import moment from "moment";

import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";

import GridDX from "../../layout/griddx";
import ListPageDX from "../../business/listpagedx";
import useInboxService from "../../../shared/services/inboxservice";
import {
  concatenateNameWithRole,
  DateFormatter,
  DateSorter,
} from "../../../shared/globals";
import { PrintDocumentWithBarcode } from "../../../shared/pdfoperations";

const Inbox = () => {
  const { t, i18n } = useTranslation();

  const { userData } = useAuthContext();
  const { userId, calendarId, gregorianDateFormatId, hijriDateFormatId } =
    userData;
  const [isLoading, setIsLoading] = useState(false);
  const [inboxData, setInboxData] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const { setError, setInfo } = useNotificationContext();
  const {
    getInboxByUser,
    markAsRead,
    markAsUnread,
    markAsArchived,
    deleteInbox,
  } = useInboxService();

  const navigate = useNavigate();

  const getInboxData = async () => {
    setIsLoading(true);
    getInboxByUser(userId)
      .then((res: any) => {
        const formattedRows = res.map((item: any) => {
          // Check if openedDatetime is null, and set message as "New" if it is.
          const isNew = item.openedDatetime === null;

          const actionTypeMessages: { [key: number]: string } = {
            1: isNew
              ? t("New Correspondence request For Information")
              : t("Correspondence request For Information"),
            2: isNew
              ? t("New Correspondence request For Approval")
              : t("Correspondence request For Approval"),
            3: isNew
              ? t("New Correspondence request For Delivery")
              : t("Correspondence request For Delivery"),
          };
          const message = actionTypeMessages[item.requiredActionTypeId] || "";
          return {
            ...item,
            message,
          };
        });

        setRows(
          formattedRows
            .filter((item: any) => item.archivedDate === null)
            .reverse()
        );
        setInboxData(formattedRows.reverse());
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getInboxData();
  }, [i18n.language]);

  const toPreview = (data: any) => {
    console.log(data);
    markAsRead([data.inboxId]);
    navigate(
      `/cts/correspondencedetails/${data.correspondence.correspondenceId}`,
      {
        state: { delegateUser: data.delegateUser },
      }
    );
  };

  const handleMarkAsRead = (ids: any) => {
    // Implement marking selected messages as read
    markAsRead(ids)
      .then((res: any) => {
        setInfo(t("Marked as Read Successfully"));
        getInboxData();
      })
      .catch((err) => setError(err));
  };

  const handleMarkAsUnRead = (ids: any) => {
    // Implement marking selected messages as read

    markAsUnread(ids)
      .then((res: any) => {
        setInfo(t("Marked as Unread Successfully"));
        getInboxData();
      })
      .catch((err) => setError(err));
  };

  const handleDeleteSelected = (ids: any) => {
    // Implement deleting selected messages
    deleteInbox(ids)
      .then((res: any) => {
        setInfo(t("Deleted Successfully"));
        getInboxData();
      })
      .catch((err) => setError(err));
  };

  const handleArchive = (ids: any) => {
    // Implement archiving selected messages
    markAsArchived(ids)
      .then((res: any) => {
        setInfo(t("Archived Successfully"));
        getInboxData();
      })
      .catch((err) => setError(err));
  };

  const filterRead = (props: any) => {
    setRows(
      inboxData.filter(
        (item: any) => item.openedDatetime !== null && item.archivedDate == null
      )
    );
  };

  const filterUnRead = (props: any) => {
    setRows(
      inboxData.filter(
        (item: any) => item.openedDatetime == null && item.archivedDate == null
      )
    );
  };

  const filterArchived = (props: any) => {
    setRows(inboxData.filter((item: any) => item.archivedDate !== null));
  };

  const resetFilter = (props: any) => {
    setRows(inboxData.filter((item: any) => item.archivedDate == null));
  };
  const getImportanceChipColor = (level: string) => {
    if (level === "Urgent") return "error";
    else return "info";
  };
  const getPrivacyChipColor = (level: string) => {
    if (level === "High Confidential") return "error";
    else if (level === "Confidential") return "warning";
    else return "info";
  };

  const columns: GridColDef[] = [
    {
      field: "correspondenceId",
      headerName: t("ID"),
      width: 20,
    },
    {
      field: "correspondenceNumber",
      headerName: `${t("Number")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 125,
    },
    {
      field: "message",
      headerName: t("Message"),
      minWidth: 750,
      renderCell: (params) => {
        const Unread = !params.row.openedDatetime;
        return (
          <span style={{ fontWeight: Unread ? "bold" : "normal" }}>
            {params.row.message}
            {params.row.delegateUser && (
              <Chip
                icon={
                  <AssignmentIndOutlined fontSize="small" color="inherit" />
                }
                sx={{
                  height: "25px",

                  mx: 2,
                  display: "inline-flex",
                  justifyContent: "center",
                  backgroundColor: "#007bff",
                  color: "#fff",
                }}
                label={
                  i18n.language === "en"
                    ? params.row.delegateUser.enFullName
                    : params.row.delegateUser.arFullName
                }
              />
            )}

            {params.row.correspondence?.correspondenceForwards.length > 0 && (
              <Chip
                sx={{
                  height: "25px",
                  m: 0.5,
                  display: "inline-flex",
                  justifyContent: "center",
                  backgroundColor: "#B7CEDE",
                }}
                label={t("CC")}
              />
            )}
            {params.row.correspondence?.importanceLevel && (
              <Chip
                sx={{
                  height: "25px",
                  m: 0.5,
                  display: "inline-flex",
                  justifyContent: "center",
                  color: "#fff",
                }}
                label={
                  i18n.language === "en"
                    ? params.row.correspondence?.importanceLevel.enName
                    : params.row.correspondence?.importanceLevel.arName
                }
                color={getImportanceChipColor(
                  params.row.correspondence?.importanceLevel.enName
                )}
              />
            )}
            {params.row.correspondence?.privacyLevel && (
              <Chip
                sx={{
                  height: "25px",

                  m: 0.5,
                  display: "inline-flex",
                  justifyContent: "center",
                  color: "#fff",
                }}
                label={
                  i18n.language === "en"
                    ? params.row.correspondence?.privacyLevel.enName
                    : params.row.correspondence?.privacyLevel.arName
                }
                color={getPrivacyChipColor(
                  params.row.correspondence?.privacyLevel.enName
                )}
              />
            )}
            {/* //dueDate */}
            {params.row.correspondence?.dueDate && (
              <Chip
                sx={{
                  height: "25px",

                  m: 0.5,
                  display: "inline-flex",
                  justifyContent: "center",
                  color: "#fff",
                }}
                icon={<HourglassBottomOutlined />}
                label={
                  new Date(
                    params.row.correspondence?.dueDate
                  ).toLocaleDateString() < new Date().toLocaleDateString()
                    ? t("Expired")
                    : DateFormatter(
                        params.row.correspondence?.dueDate,
                        calendarId,
                        gregorianDateFormatId,
                        hijriDateFormatId
                      )
                }
                color={
                  new Date(
                    params.row.correspondence?.dueDate
                  ).toLocaleDateString() < new Date().toLocaleDateString()
                    ? "error"
                    : "info"
                }
              />
            )}
          </span>
        );
      },
    },
    {
      field: "from",
      headerName: t("From"),
      headerAlign: "center",
      align: "center",
      width: 250,
      valueGetter(params) {
        return concatenateNameWithRole(params.row.correspondence.routedByUser);
      },
    },
    {
      field: "date",
      headerName: t("Date"),
      minWidth: 160,
      valueGetter(params) {
        return DateFormatter(
          params.row.correspondence.correspondenceDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  const onPrintBarcode = (data: any) => {
    const timeStamp = DateFormatter(
      moment(data.correspondence.correspondenceDate),
      calendarId,
      gregorianDateFormatId,
      hijriDateFormatId
    );
    const corrsNumber = data.correspondence.correspondenceNumber;
    const textLabelPositionData = [
      {
        label: "Number",
        value: corrsNumber,
        position: { x: 10, y: 800 },
      },
      {
        label: "Date",
        value: timeStamp,
        position: { x: 10, y: 780 },
      },
    ];

    PrintDocumentWithBarcode(textLabelPositionData, corrsNumber);
  };

  return (
    <GridDX container sx={{ width: "100%" }}>
      <GridDX item xs={3} sm={12} justifyContent={"center"}>
        <ListPageDX
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.inboxId}
          isLoading={isLoading}
          onEdit={toPreview}
          editLabel={t("Preview")}
          exportToPDF={false}
          exportToCSV={false}
          searchAndFilter={false}
          checkboxSelection={true}
          customActions={[
            {
              label: t("Mark as Read"),
              onClick: handleMarkAsRead,
            },
            {
              label: t("Mark as Unread"),
              onClick: handleMarkAsUnRead,
            },
            {
              label: t("Archive Selected"),
              onClick: handleArchive,
            },
            {
              label: t("Delete Selected"),
              onClick: handleDeleteSelected,
            },
          ]}
          customFilters={[
            {
              label: t("Read"),
              onClick: filterRead,
            },
            {
              label: t("Unread"),
              onClick: filterUnRead,
            },
            {
              label: t("Archived"),
              onClick: filterArchived,
            },
            {
              label: t("Reset"),
              onClick: resetFilter,
            },
          ]}
          columnVisibilityModel={{
            correspondenceId: false,
          }}
          onPrintBarcode={onPrintBarcode}
          printBarcodeLabel={t("Print Barcode")}
        />
      </GridDX>
    </GridDX>
  );
};

export default Inbox;
