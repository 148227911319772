import useSecureAPI from "../hooks/usesecureapi";

const useAttachmentService = () => {
  const secureAPI = useSecureAPI();

  const getAttachments = async (id: number) => {
    const response = await secureAPI.get(`Attachment/forcorrespondence/${id}`);
    return response.data;
  };

  const downloadAttachment = async (id: number) => {
    const response = await secureAPI.get(`Attachment/download/${id}`);
    return response.data;
  };

  const addAttachments = async (data: any) => {
    const response = await secureAPI.post(`Attachment/`, data);
    return response.data;
  };

  const deleteAttachment = async (id: number) => {
    const response = await secureAPI.delete(`Attachment/${id}`);
    return response.data;
  };

  return {
    getAttachments,
    downloadAttachment,
    addAttachments,
    deleteAttachment,
  };
};

export default useAttachmentService;
