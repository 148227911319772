import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

import GridDX from "../../../components/layout/griddx";
import WhiteBox from "../../../components/layout/whitebox";
import ChartBox from "../../../components/layout/chartbox";
import CorrespondenceListTabs from "./correspondencelisttabs";

import useDashboardService from "../../../shared/services/dashboardservice";

import { useNotificationContext } from "../../../context/notificationcontext";
import FilterRange from "../../../components/controls/filterrange";
import ActionDropDownMenuDx from "../../../components/controls/dropdownmenudx";
import { useAuthContext } from "../../../context/authcontext";
import momentHijri from "moment-hijri";
import { APIDateFormatter } from "../../../shared/globals";
import useCorrespondenceTypeService from "../../../shared/services/correspondencetypes";
import useDomainTypeService from "../../../shared/services/domaintypeservice";
import PageTitle from "../../../components/pagetitle";

const Correspondence = () => {
  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const { t } = useTranslation();
  const { setError } = useNotificationContext();
  const {
    getSentAndNotReceivedCount,
    getRequiresMyActionCount,
    getOverDueBreakupCount,
    getOpenBreakupCount,
  } = useDashboardService();
  const [isLoading, setIsLoading] = useState(true);
  const [sentAndNotReceivedCount, setSentAndNotReceivedCount] = useState(0);
  const [requiresMyActionCount, setRequiresMyActionCount] = useState(0);
  const [overdueBreakup, setOverdueBreakup] = useState<any>([
    { id: 0, value: 0, label: t("By Me") },
    { id: 1, value: 0, label: t("By Others") },
  ]);
  const [openBreakup, setOpenBreakup] = useState<any>([
    { id: 0, value: 0, label: t("By Me") },
    { id: 1, value: 10, label: t("By Others") },
  ]);

  const { getCorrespondenceType } = useCorrespondenceTypeService();
  const { getDomainTypes } = useDomainTypeService();
  const [correspondenceTypeId, setCorrespondenceTypeId] = useState<any>(null);
  const [domainTypeId, setDomainTypeId] = useState<any>(null);
  const date = new Date();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    calendarId == 1 ? date.setDate(date.getDate() - 30) : momentHijri(date)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    calendarId == 1 ? new Date() : momentHijri(new Date())
  );
  const handleChangeFromDate = (date: any) => {
    setFilterFromDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    }
  };
  const handleChangeToDate = (date: any) => {
    setFilterToDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));

    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    }
  };
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const filters = [
    {
      label: t("Internal Incoming"),
      domain: "internal",
      correspondence: "incoming",
    },
    {
      label: t("Internal Outgoing"),
      domain: "internal",
      correspondence: "outgoing",
    },
    {
      label: t("External Incoming"),
      domain: "external",
      correspondence: "incoming",
    },
    {
      label: t("External Outgoing"),
      domain: "external",
      correspondence: "outgoing",
    },
    { label: t("Reset"), domain: null, correspondence: null },
  ].map((filter, index) => ({
    label: filter.label,
    onClick: () => {
      setSelectedFilter(index === 4 ? null : index);
      setDomainTypeId(
        filter.domain
          ? domainTypes.find(
              (x: any) => x.enName.toLowerCase() === filter.domain
            )?.domainTypeId
          : null
      );
      setCorrespondenceTypeId(
        filter.correspondence
          ? correspondenceTypes.find(
              (x: any) => x.enName.toLowerCase() === filter.correspondence
            )?.correspondenceTypeId
          : null
      );
    },
  }));

  const [domainTypes, setDomainTypes] = useState<any>([]);
  const [correspondenceTypes, setCorrespondenceTypes] = useState<any>([]);

  useEffect(() => {
    const p1 = getCorrespondenceType();
    const p2 = getDomainTypes();
    Promise.all([p1, p2])
      .then(([types, domains]) => {
        setCorrespondenceTypes(types);
        setDomainTypes(domains);
      })
      .catch((err) => setError(err));
  }, []);

  const getCorrespondenceCardsData = () => {
    setIsLoading(true);
    Promise.all([
      getSentAndNotReceivedCount(
        APIDateFormatter(filterFromDate),
        APIDateFormatter(filterToDate),
        correspondenceTypeId,
        domainTypeId
      ).then((data: number) => setSentAndNotReceivedCount(data)),
      getRequiresMyActionCount(
        APIDateFormatter(filterFromDate),
        APIDateFormatter(filterToDate),
        correspondenceTypeId,
        domainTypeId
      ).then((data: number) => setRequiresMyActionCount(data)),
      getOverDueBreakupCount(
        APIDateFormatter(filterFromDate),
        APIDateFormatter(filterToDate),
        correspondenceTypeId,
        domainTypeId
      ).then((data: any) => {
        setOverdueBreakup((oldValue: any) => {
          const newValue = oldValue.slice();

          newValue[0].value = data.overdueByMe;
          newValue[1].value = data.overdueByOthers;

          return newValue;
        });
      }),
      getOpenBreakupCount(
        APIDateFormatter(filterFromDate),
        APIDateFormatter(filterToDate),
        correspondenceTypeId,
        domainTypeId
      ).then((data: any) =>
        setOpenBreakup((oldValue: any) => {
          const newValue = oldValue.slice();

          newValue[0].value = data.openByMe;
          newValue[1].value = data.openByOthers;

          return newValue;
        })
      ),
    ])
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getCorrespondenceCardsData();
  }, [filterFromDate, filterToDate, correspondenceTypeId, domainTypeId]);
  return (
    <GridDX
      container
      sx={{ width: "100%", alignItems: "center" }}
      rowSpacing={2}
      columnSpacing={1}
    >
      <GridDX item xs={2}>
        <PageTitle />
      </GridDX>
      <GridDX item xs={10} justifyContent="end">
        <GridDX>
          <ActionDropDownMenuDx
            label={filters[selectedFilter]?.label || t("Filter")}
            actions={filters}
          />
        </GridDX>
        <FilterRange
          filterFromDate={filterFromDate}
          filterToDate={filterToDate}
          handleChangeFromDate={handleChangeFromDate}
          handleChangeToDate={handleChangeToDate}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <WhiteBox
          title={t("Sent & Not Recieved")}
          loading={isLoading}
          count={sentAndNotReceivedCount}
          icon={<ForwardToInboxOutlinedIcon sx={{ fontSize: 100 }} />}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <WhiteBox
          title={t("Requires my Action")}
          loading={isLoading}
          count={requiresMyActionCount}
          icon={<MarkEmailReadOutlinedIcon sx={{ fontSize: 100 }} />}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <ChartBox
          title={t("Overdue")}
          loading={isLoading}
          data={overdueBreakup}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <ChartBox title={t("Open")} loading={isLoading} data={openBreakup} />
      </GridDX>
      <GridDX item xs={12}>
        <CorrespondenceListTabs
          filterFromDate={APIDateFormatter(filterFromDate)}
          filterToDate={APIDateFormatter(filterToDate)}
          correspondenceTypeId={correspondenceTypeId}
          domainTypeId={domainTypeId}
          getCorrespondenceCardsData={getCorrespondenceCardsData}
        />
      </GridDX>
    </GridDX>
  );
};

export default Correspondence;
