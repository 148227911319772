import useSecureAPI from "../hooks/usesecureapi";

const useActionTrailService = () => {
  const secureAPI = useSecureAPI();

  const getAllActionTrails = async (startDate: string, endDate: string) => {
    const response = await secureAPI.get(`ActionTrail/${startDate}/${endDate}`);
    return response.data;
  };

  return {
    getAllActionTrails,
  };
};

export default useActionTrailService;
