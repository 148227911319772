import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import ButtonDX from "./buttondx";
import GridDX from "../layout/griddx";
import { useTranslation } from "react-i18next";
import Loading from "../loading";
const DeleteModal = (props: any) => {
  const {
    open,
    onCancelClick,
    onDeleteClick,
    deleteLabel,
    name,
    deleteMessage,
    isLoading,
  } = props;
  const { t, i18n } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        // to prevent closing the dialog when clicking outside while loading
        if (isLoading && reason === "backdropClick") return;
        onCancelClick();
      }}
      fullWidth
      maxWidth={"xs"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("Are You Sure?")}
      </DialogTitle>
      <DialogContent>
        <GridDX justifyContent="center" direction="column">
          <Typography sx={{ textAlign: "center" }}>
            {deleteMessage && deleteMessage.length > 0 ? (
              deleteMessage
            ) : deleteLabel == t("Archive") ? (
              <>
                {t("You want to archive this")}&nbsp;{t(name || "entry")}
              </>
            ) : (
              <>
                {t("You want to delete this")}&nbsp;{t(name || "entry")}
              </>
            )}
          </Typography>
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <ButtonDX variant="outlined" onClick={onCancelClick}>
          {t("Cancel")}
        </ButtonDX>
        <ButtonDX onClick={onDeleteClick}>
          {t(deleteLabel || "Delete")}
        </ButtonDX>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
