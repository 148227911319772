import { useEffect, useState } from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import ListPageDX from "../../../components/business/listpagedx";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DateFormatter, DateSorter } from "../../../shared/globals";
import AppointmentModal from "../../../components/editmodals/VMS/appointment/appointmentmodal";
import TypeTranslator from "../../../shared/typetranslator";
import useAppointmentService from "../../../shared/services/vmsappointmentservice";
import { Chip } from "@mui/material";
import { Archive, Download, Edit, Preview, Print } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AppointmentReport from "../../../components/pages/reports/Vms/appointmentreport";
import useCountryService from "../../../shared/services/countryservice";
import { convertPdfToImages } from "../../../shared/pdfoperations";

const AppointmentsList = (props: any) => {
  const { filterFromDate, filterToDate } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const { archiveAppointment, getAppointmentById, getFilteredAppointments } =
    useAppointmentService();
  const { getCountries } = useCountryService();
  const { userData } = useAuthContext();
  const {
    getAppointmentTypeValue,
    getAppointmentStatusChipColor,
    getAppointmentStatusValue,
  } = TypeTranslator();

  const navigate = useNavigate();

  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const [appointmentId, setAppointmentId] = useState<any>(null);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [appointmentData, setAppointmentData] = useState<any>(null);
  const [showReportPreview, setShowReportPreview] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "appointmentType",
      headerName: `${t("Appointment Type")}`,
      flex: 1,
      valueGetter: (params: any) =>
        getAppointmentTypeValue(params?.row?.appointmentTypeId),
    },
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.organizationName;
      },
    },
    {
      field: "visitPurpose",
      headerName: `${t("Visit Purpose")}`,
      flex: 1,
    },
    {
      field: "visitFromDate",
      headerName: `${t("From")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "visitEndDate",
      headerName: `${t("To")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Chip
            sx={{ m: 0.5, height: 25 }}
            label={getAppointmentStatusValue(params.value)}
            color={getAppointmentStatusChipColor(params.value)}
          />
        );
      },
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getFilteredAppointments(filterFromDate, filterToDate)
      .then((appointments) => {
        appointments?.reverse();
        const formatedData = appointments.map((data: any) => {
          return {
            ...data,
            visitFromDate: DateFormatter(
              data.visitFromDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            visitEndDate: DateFormatter(
              data.visitEndDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            appointmentType: getAppointmentTypeValue(data.appointmentTypeId),
          };
        });
        setRows(formatedData);
        setDataFromApi(formatedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveAppointment(id)
      .then(() => {
        setInfo(t("Appointment archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  const onEdit = (data: any) => {
    setAppointmentId(data.appointmentId);
    setOpen(!open);
  };

  const toPreview = (data: any) => {
    navigate(`/vms/appointmentdetails/${data.appointmentId}`, {
      state: { delegateUser: data.delegateUser },
    });
  };
  const toCreate = () => {
    setOpen(!open);
  };

  const onPrint = async (data: any) => {
    setIsLoading(true);
    getAppointmentById(data.appointmentId)
      .then(async (response) => {
        const updatedAppointmentData = await processVisitorDocuments(response);
        setAppointmentData(updatedAppointmentData);
        setShowReportPreview(true);
      })
      .catch((error) => {
        setError(t("Error printing appointment"));
        console.error("Error fetching appointment:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const processVisitorDocuments = async (appointment: any) => {
    const updatedVisitors = await Promise.all(
      appointment.visitors.map(async (visitor: any) => {
        const updatedDocuments = await Promise.all(
          visitor.visitorDocuments.map(async (doc: any) => {
            const imageSrc = await fetchDocumentImage(doc.downloadURL);
            return {
              ...doc,
              imageSrc,
            };
          })
        );

        return {
          ...visitor,
          visitorDocuments: updatedDocuments,
        };
      })
    );

    return {
      ...appointment,
      visitors: updatedVisitors,
    };
  };

  const fetchDocumentImage = async (downloadURL: string) => {
    try {
      const response = await fetch(downloadURL, {
        headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
      });

      const contentType = response.headers.get("content-type");
      //@ts-ignore
      if (contentType.includes("image")) {
        // If the file is an image
        const blob = await response.blob();
        return window.URL.createObjectURL(blob); // Return the image URL
        //@ts-ignore
      } else if (contentType.includes("pdf")) {
        // If the file is a PDF, handle it accordingly
        // (we will convert PDF to image)
        const blob = await response.blob();
        const file = new File([blob], "document.pdf", {
          type: "application/pdf",
        });
        // Convert PDF to images using the existing method
        const images = await convertPdfToImages(file);
        // Return the first image as the preview, or return all if you need a gallery
        return images.length > 0 ? images[0] : null;
      } else {
        console.warn("Unsupported document type:", contentType);
        return null;
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      return null; // Return null in case of error
    }
  };

  const onClose = (refreshPage: boolean = false) => {
    setAppointmentId(null);
    setOpen(!open);
    if (refreshPage) getData();
  };
  useEffect(() => {
    getData();
  }, [i18n.language, filterFromDate, filterToDate]);
  const [countries, setCountries] = useState<any>([]);
  useEffect(() => {
    setIsLoading(true);
    getCountries()
      .then((response) => {
        setCountries(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = dataFromApi.filter(
      ({
        enName,
        arName,
        visitFromDate,
        visitEndDate,
        organizationName,
        appointmentTypeId,
        visitPurpose,
        status,
      }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            organizationName?.toLowerCase().includes(lowercasedValue) ||
            visitFromDate?.includes(value) ||
            visitEndDate?.includes(value) ||
            getAppointmentTypeValue(appointmentTypeId)
              ?.toString()
              ?.toLowerCase()
              .includes(lowercasedValue) ||
            visitPurpose.toString()?.toLowerCase().includes(lowercasedValue) ||
            getAppointmentStatusValue(status)
              .toLowerCase()
              .includes(lowercasedValue)
          : arName?.includes(value) ||
            organizationName?.includes(value) ||
            visitFromDate?.includes(value) ||
            visitEndDate?.includes(value) ||
            getAppointmentTypeValue(appointmentTypeId)
              ?.toString()
              ?.toLowerCase()
              .includes(lowercasedValue) ||
            visitPurpose.toString()?.toLowerCase().includes(lowercasedValue) ||
            status.toString()?.toLowerCase().includes(lowercasedValue)
    );
    setRows(newRows);
  };

  const buildActions = (params: GridRowParams) => {
    let actionsArray: any = [];
    if (params.row.status === 1) {
      actionsArray.push(
        <GridActionsCellItem
          label={t("Edit")}
          showInMenu
          onClick={() => {
            onEdit(params.row);
          }}
          icon={<Edit />}
        />
      );
    } else {
      actionsArray.push(
        <GridActionsCellItem
          label={t("Preview")}
          showInMenu
          onClick={() => {
            toPreview(params.row);
          }}
          icon={<Preview />}
        />
      );
    }
    actionsArray.push(
      <GridActionsCellItem
        label={t("Archive")}
        showInMenu
        onClick={() => {
          onDelete(params.row.appointmentId);
        }}
        icon={<Archive />}
      />
    );

    actionsArray.push(
      <GridActionsCellItem
        label={t("Print")}
        showInMenu
        onClick={() => {
          onPrint(params.row);
        }}
        icon={<Print />}
      />
    );

    return actionsArray;
  };

  const onRowClick = (params: GridRowParams) => {
    if (params.row.status === 1) {
      onEdit(params.row);
    } else {
      toPreview(params.row);
    }
  };
  return (
    <>
      {open && (
        <AppointmentModal
          open={open}
          handleClose={onClose}
          appointmentId={appointmentId}
        />
      )}
      {showReportPreview && appointmentData && (
        <AppointmentReport
          open={showReportPreview}
          onClose={() => setShowReportPreview(false)}
          appointmentData={appointmentData}
          countries={countries}
        />
      )}
      <ListPageDX
        listTitle={t("Appointments")}
        name={t("Appointments")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.appointmentId}
        isLoading={isLoading}
        toCreate={toCreate}
        // onEdit={onEdit}
        // onDelete={onDelete}
        buildActions={buildActions}
        onRowClick={onRowClick}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default AppointmentsList;
