import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import DateTimePickerDX from "../../controls/datetimepickerdx";

import moment from "moment";
import momentHijri from "moment-hijri";
import { useAuthContext } from "../../../context/authcontext";

const RemindersModal = (props: any) => {
  const { open, handleClose, reminder, toSave, savingReminder } = props;

  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const { t } = useTranslation();

  const defaultValues = {
    description: "",
    reminderDate: null,
  };

  const [reminderData, setReminderData] = useState<any>(defaultValues);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (reminder) setReminderData(reminder);
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (reminderData.description.length === 0)
      newErrors["description"] = t("Reminder description is required");
    if (!reminderData.reminderDate)
      newErrors["reminderDate"] = t("Reminder date and time is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      toSave(reminder === null, reminderData);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setReminderData({
      ...reminderData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleDateTimeChange = (e: any) => {
    setIsChanged(true);
    setReminderData({
      ...reminderData,
      reminderDate: moment(e),
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={savingReminder}
      isChanged={isChanged}
      title={reminder ? t("Edit Reminder") : t("Add Reminder")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {savingReminder && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX
          item
          xs={12}
          sx={{
            "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
              width: "100% !important",
            },
            "& .css-1rutzcu-MuiFormControl-root-MuiTextField-root": {
              width: "100% !important",
            },
          }}
        >
          <DateTimePickerDX
            label={t("Reminder Date and Time")}
            name="reminderDate"
            value={reminderData.reminderDate}
            onChange={handleDateTimeChange}
            error={errors["reminderDate"]}
            minDate={calendarId === 1 ? moment() : momentHijri()}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Reminder Description")}
            name="description"
            value={reminderData.description}
            onChange={handleInputChange}
            error={errors["description"]}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default RemindersModal;
