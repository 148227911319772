import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import CheckBoxDX from "../controls/checkboxdx";
import { useNotificationContext } from "../../context/notificationcontext";
import useUserService from "../../shared/services/userservices";
import SelectListDX from "../controls/selectlistdx";
import AutoCompleteMultiple from "../controls/autocompletemultiple";
import useUserGroupService from "../../shared/services/usergroupservice";
import Loading from "../loading";
import { RegularExpressions, validatePassword } from "../../shared/globals";
import useOrganizationRoleService from "../../shared/services/userroleservice";
import BoxDX from "../layout/boxdx";
import AutoCompleteListDX from "../controls/autocompletelistdx";

const UserModal = (props: any) => {
  const { userId, open, handleClose } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";

  const { setError, setInfo } = useNotificationContext();
  const { getActiveUsers, addUser, updateUser, getUserById } = useUserService();
  const { getUserGroups } = useUserGroupService();
  const { getOrganizationRoles } = useOrganizationRoleService();
  const defaultValues = {
    email: "",
    password: "",
    enFullName: "",
    arFullName: "",
    phoneNumber: "",
    mobile: "",
    enJobTitle: "",
    arJobTitle: "",
    languageId: 1,
    calendarId: 1,
    hijriDateFormatId: 1,
    gregorianDateFormatId: 1,
    managerId: null,
    isAdmin: false,
    isActive: true,
    receiveEmailsOnTeamOverdueItems: false,
    receiveEmailsOnOverdueItems: false,
    receiveEmailsForRequireActionItems: false,
    roleIds: [],
    organizationRoleId: null,
    modules: "",
  };

  const languageDropDownData = [
    { value: 1, text: t("English") },
    { value: 2, text: t("Arabic") },
  ];
  const calendarDropDownData = [
    { value: 1, text: t("Gregorian") },
    { value: 2, text: t("Hijri") },
  ];
  const hijriCalendarDropDownData = [
    { value: 1, text: t("Short") },
    { value: 2, text: t("Long") },
  ];
  const gregorianCalendarDropDownData = [
    { value: 1, text: t("Short") },
    { value: 2, text: t("Long") },
  ];

  const [userData, setUserData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const [usersDropDownData, setUsersDropDownData] = useState<any>([]);
  const [groupsDropDownData, setGroupsDropDownData] = useState<any>([]);
  const [userRolesDropDownData, setUserRolesDropDownData] = useState<any>([]);
  const modulesData = [
    { id: "1", label: "DMS" },
    { id: "2", label: "CTS" },
    { id: "3", label: "VMS" },
  ];

  useEffect(() => {
    setIsLoading(true);
    const p1 = getActiveUsers();
    const p2 = getUserGroups();
    const p3 = userId ? getUserById(userId) : null;
    const p4 = getOrganizationRoles();
    Promise.all([p1, p2, p3, p4])
      .then(([users, groups, user, userRoles]) => {
        const filteredUsers = users?.filter((res: any) => userId !== res.id);
        const dataForUsersDropDown = filteredUsers.map((res: any) => ({
          text: languageIsEn ? res.enFullName : res.arFullName,
          value: res.id,
        }));
        const dataForGroupsDropDown = groups.map((res: any) => ({
          text: languageIsEn ? res.enName : res.arName,
          value: res.id,
        }));
        const dataForUserRolesDropDown = userRoles.map((res: any) => ({
          text: languageIsEn ? res.enName : res.arName,
          value: res.organizationRoleId,
        }));

        setUsersDropDownData(dataForUsersDropDown);
        setGroupsDropDownData(dataForGroupsDropDown);
        setUserRolesDropDownData(dataForUserRolesDropDown);

        if (userId) {
          const { roles, ...rest } = user;
          // to populate already selected group
          const updatedGroupsDataForDropDown = roles.map((res: any) => ({
            text: languageIsEn ? res.enName : res.arName,
            value: res.id,
          }));
          setUserData({
            ...rest,
            password: "",
            roleIds: updatedGroupsDataForDropDown,
          });
        }
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (userData.password !== "") {
      const error = validatePassword(userData.password);
      if (error !== "") newErrors["password"] = t(error);
    } else if (!userId && userData.password.length === 0)
      newErrors["password"] = t("Password is required");

    if (userData.enFullName.length === 0)
      newErrors["enFullName"] = t("English name is required");
    if (userData.arFullName.length === 0)
      newErrors["arFullName"] = t("Arabic name is required");
    if (userData.email.length === 0)
      newErrors["email"] = t("Email is required");
    else if (!RegularExpressions.emailAddress.test(userData.email))
      newErrors["email"] = t("Invalid email address");
    if (userData.languageId === null)
      newErrors["languageId"] = t("Language is required");
    if (userData.calendarId === null)
      newErrors["calendarId"] = t("Calendar is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);

    const { roleIds } = userData;
    const roles = roleIds.map((e: any) => e.value);
    const operation = userId
      ? updateUser(userId, { ...userData, roleIds: roles })
      : addUser({ ...userData, roleIds: roles });
    operation
      .then(() => {
        setInfo(
          t(userId ? "User updated successfully" : "User created successfully")
        );
        handleClose(true);
      })
      .catch((err) => {
        if (err === "USER_ALREADY_EXISTS") {
          setError(
            t(`Email`) + " " + userData.email + " " + t("is already taken")
          );
        } else {
          setError(err);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setUserData({
      ...userData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={userId ? t("Edit User") : t("Create User")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("English Name")}
            name="enFullName"
            value={userData.enFullName}
            onChange={handleInputChange}
            error={errors["enFullName"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arFullName"
            value={userData.arFullName}
            onChange={handleInputChange}
            error={errors["arFullName"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("New Password")}
            name="password"
            value={userData.password}
            onChange={handleInputChange}
            error={errors["password"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            disabled={userId}
            label={t("Email")}
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            error={errors["email"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Phone")}
            name="phoneNumber"
            value={userData.phoneNumber}
            type="number"
            onChange={handleInputChange}
            error={errors["phoneNumber"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Mobile")}
            name="mobile"
            type="number"
            value={userData.mobile}
            onChange={handleInputChange}
            error={errors["mobile"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("English Job Title")}
            name="enJobTitle"
            value={userData.enJobTitle}
            onChange={handleInputChange}
            error={errors["enJobTitle"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Arabic Job Title")}
            name="arJobTitle"
            value={userData.arJobTitle}
            onChange={handleInputChange}
            error={errors["arJobTitle"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Select Language")}
            items={languageDropDownData}
            name="languageId"
            value={userData.languageId}
            onChange={handleInputChange}
            error={errors["languageId"]}
            InputLabelProps={{
              shrink: userData.languageId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Select Calendar")}
            items={calendarDropDownData}
            name="calendarId"
            value={userData.calendarId}
            onChange={handleInputChange}
            error={errors["calendarId"]}
            InputLabelProps={{
              shrink: userData.calendarId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Hijri Calendar Format")}
            items={hijriCalendarDropDownData}
            name="hijriDateFormatId"
            value={userData.hijriDateFormatId}
            onChange={handleInputChange}
            error={errors["hijriDateFormatId"]}
            InputLabelProps={{
              shrink: userData.hijriDateFormatId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Gregorian Calendar Format")}
            items={gregorianCalendarDropDownData}
            name="gregorianDateFormatId"
            value={userData.gregorianDateFormatId}
            onChange={handleInputChange}
            error={errors["gregorianDateFormatId"]}
            InputLabelProps={{
              shrink: userData.gregorianDateFormatId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={12}>
          <AutoCompleteListDX
            label={t("Manager")}
            items={usersDropDownData}
            name="managerId"
            value={userData.managerId}
            onChange={handleInputChange}
            error={errors["managerId"]}
            InputLabelProps={{
              shrink: userData.managerId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={12}>
          <AutoCompleteMultiple
            label={t("Groups")}
            name="roleIds"
            placeholder={t("Group")}
            list={groupsDropDownData}
            value={userData?.roleIds}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setUserData({
                ...userData,
                roleIds: newValue,
              });
            }}
            error={errors["roleIds"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteListDX
            label={t("Select Role")}
            items={userRolesDropDownData}
            name="organizationRoleId"
            value={userData.organizationRoleId}
            onChange={handleInputChange}
            error={errors["organizationRoleId"]}
            InputLabelProps={{
              shrink: userData.organizationRoleId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX xs={12}>
          <GridDX
            component="fieldset"
            sx={{
              ml: 1,
              mr: 0,
              height: 65,
              width: "100%",
              border: "1px solid #c6c6c6",
              borderRadius: "4px",
            }}
          >
            <legend style={{ color: "#808080", fontSize: 12 }}>
              {t("Modules")}
            </legend>
            <>
              {modulesData.map(({ id, label }) => (
                <BoxDX key={id} sx={{ mx: 1 }}>
                  <CheckBoxDX
                    sx={{ p: 0, px: 0.2 }}
                    name="modules"
                    label={t(label)}
                    checked={userData?.modules?.split(",").includes(id)}
                    onChange={() => {
                      setIsChanged(true);
                      let modules = userData?.modules?.split(",") || [];
                      if (modules.includes(id)) {
                        modules = modules.filter((m: any) => m !== id);
                      } else {
                        modules.push(id);
                      }
                      modules = modules.filter(Boolean).sort().join(",");
                      setUserData({ ...userData, modules });
                    }}
                  />
                </BoxDX>
              ))}
            </>
          </GridDX>
        </GridDX>

        <GridDX item xs={12}>
          <CheckBoxDX
            name="isActive"
            label={t("Active")}
            checked={userData.isActive}
            onChange={handleInputChange}
          />
        </GridDX>

        <GridDX item xs={12}>
          <CheckBoxDX
            name="isAdmin"
            label={t("This user is a system admin")}
            checked={userData.isAdmin}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CheckBoxDX
            name="receiveEmailsOnTeamOverdueItems"
            label={t("Receive emails on team's overdue items")}
            checked={userData.receiveEmailsOnTeamOverdueItems}
            onChange={handleInputChange}
          />
        </GridDX>

        <GridDX item xs={12}>
          <CheckBoxDX
            name="receiveEmailsForRequireActionItems"
            label={t("Receive reminders of require-action items")}
            checked={userData.receiveEmailsForRequireActionItems}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CheckBoxDX
            name="receiveEmailsOnOverdueItems"
            label={t("Receive reminders on overdue and require-action items")}
            checked={userData.receiveEmailsOnOverdueItems}
            onChange={handleInputChange}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default UserModal;
