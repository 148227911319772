import GridDX from "../../components/layout/griddx";
import PageTitle from "../../components/pagetitle";
import NavigationPanel from "../../components/pages/DMS/navigationpanel";
import FileExplorer from "../../components/pages/DMS/fileexplorer";
import { useState } from "react";

const DMSExplorer = (props: any) => {

  const [activeTree, setActiveTree] = useState<any>(null);
  const [activeTrash, setActiveTrash] = useState<any>(null);
  const [activeCabinet, setActiveCabinet] = useState<any>(null);
  const [sharedContent, setSharedContent] = useState<any>(null);
  const [folder, setFolder] = useState<any>(null);

  const toChangeCabinet = (cabinet: any, tree: any) => {
    setActiveCabinet(cabinet);
    setActiveTree(tree);
  };

  const toChangeFolder = (folder: any) => {
    setFolder(folder);
    setActiveTrash(null);
    setSharedContent(null);
  };

  return (
    <GridDX container sx={{ width: "100%", height: "100%" }}>
      <GridDX item xs={12}>
        <PageTitle />
      </GridDX>
      <NavigationPanel
        tree={activeTree}
        folder={folder}
        onFolderChange={toChangeFolder}
        onCabinetChange={toChangeCabinet}
        shared={sharedContent}
        trash={activeTrash}
        toOpenShared={setSharedContent}
        toOpenTrash={setActiveTrash}
      />
      <FileExplorer
        tree={activeTree}
        cabinet={activeCabinet}
        folder={folder}
        onCabinetRefresh={toChangeCabinet}
        onFolderChange={toChangeFolder}
        onDirectoryChange={setActiveTree}
        shared={sharedContent}
        trash={activeTrash}
        setActiveTrash={setActiveTrash}
      />
    </GridDX>
  );
};

export default DMSExplorer;
