import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import { useNotificationContext } from "../../context/notificationcontext";
import CheckBoxDX from "../controls/checkboxdx";
import SelectListDX from "../controls/selectlistdx";
import AddEditModalDX from "../business/addeditmodaldx";
import Loading from "../loading";
import useUserService from "../../shared/services/userservices";
import UploadBoxDX from "../controls/uploadboxdx";
import { useAuthContext } from "../../context/authcontext";
import { validatePassword } from "../../shared/globals";

const ProfileModal = (props: any) => {
  const { t } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { getUserDetails, updateUserDetails } = useAuthContext();
  const { getUserById, updateUser } = useUserService();

  const defaultValues = {
    email: "",
    enFullName: "",
    arFullName: "",
    phoneNumber: "",
    mobile: "",
    languageId: 1,
    calendarId: 1,
    gregorianDateFormatId: 1,
    hijriDateFormatId: 1,
    receiveEmailsOnTeamOverdueItems: false,
    receiveEmailsOnOverdueItems: false,
    receiveEmailsForRequireActionItems: false,
    signatureImage: null,
  };

  const defaultPasswords = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [userData, setUserData] = useState<any>(defaultValues);
  const [passwords, setPasswords] = useState<any>(defaultPasswords);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const languageDropDown = [
    { value: 1, text: t("English") },
    { value: 2, text: t("Arabic") },
  ];
  const calendarDropDown = [
    { value: 1, text: t("Gregorian") },
    { value: 2, text: t("Hijri") },
  ];
  const hijriCalendarDropDown = [
    { value: 1, text: t("Short") },
    { value: 2, text: t("Long") },
  ];
  const gregorianCalendarDropDown = [
    { value: 1, text: t("Short") },
    { value: 2, text: t("Long") },
  ];

  useEffect(() => {
    getData();
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (passwords.newPassword !== "") {
      const error = validatePassword(passwords.newPassword);
      if (error !== "") newErrors["newPassword"] = t(error);
    }
    if (passwords.oldPassword !== "") {
      const error = validatePassword(passwords.newPassword);
      if (error !== "") newErrors["oldPassword"] = t(error);
    }
    if (userData.enFullName.length === 0)
      newErrors["enFullName"] = t("English name is required");
    if (userData.arFullName.length === 0)
      newErrors["arFullName"] = t("Arabic name is required");
    if (userData.email.length === 0)
      newErrors["email"] = t("Email is required");
    if (passwords.newPassword !== "" && passwords.oldPassword === "")
      newErrors["oldPassword"] = t("Old Password is required");
    if (passwords.oldPassword !== "" && passwords.newPassword === "")
      newErrors["newPassword"] = t("New Password is required");
    if (passwords.confirmPassword !== passwords.newPassword)
      newErrors["confirmPassword"] = t("Passwords do not match");
    if (userData.languageId === null)
      newErrors["language"] = t("Select the language");
    if (userData.calendarId === null)
      newErrors["calender"] = t("Select the date.");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getData = async () => {
    setIsLoading(true);
    getUserDetails()
      .then((details: any) => {
        getUserById(details.userId)
          .then((data) => {
            setUserData(data);
            setPasswords(defaultPasswords);
          })
          .catch((err) => setError(err))
          .finally(() => setIsLoading(false));
      })
      .catch((err: any) => setError(err));
  };

  const onSave = async () => {
    if (validateForm()) {
      const data =
        passwords.newPassword === ""
          ? userData
          : {
              ...userData,
              password: passwords.newPassword,
              oldPassword: passwords.oldPassword,
            };
      setIsLoading(true);
      updateUser(userData.id, data)
        .then(() => {
          setInfo("Profile Updated Successfully");
          updateUserDetails(data);
          props.onClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const handleClose = () => {
    props.onClose(false);
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;

    setIsChanged(true);
    setUserData({
      ...userData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handlePasswordChange = (e: any) => {
    const { name, value } = e.target;

    setIsChanged(true);
    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  return (
    <AddEditModalDX
      open={props.open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("My Account")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            required
            label={t("English Name")}
            name="enFullName"
            value={userData.enFullName}
            onChange={handleInputChange}
            error={errors["enFullName"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            required
            label={t("Arabic Name")}
            name="arFullName"
            value={userData.arFullName}
            onChange={handleInputChange}
            error={errors["arFullName"]}
          />
        </GridDX>

        <GridDX item xs={12}>
          <TextFieldDX
            required
            disabled
            placeholder="user@test.com"
            label={t("Email")}
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            error={errors["email"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Old Password")}
            name="oldPassword"
            type="password"
            value={passwords.oldPassword}
            onChange={handlePasswordChange}
            error={errors["oldPassword"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("New Password")}
            name="newPassword"
            type="password"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
            error={errors["newPassword"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Confirm Password")}
            name="confirmPassword"
            type="password"
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            error={errors["confirmPassword"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Phone")}
            name="phoneNumber"
            onChange={handleInputChange}
            value={userData.phoneNumber}
            type="number"
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Mobile")}
            name="mobile"
            value={userData.mobile}
            type="number"
            onChange={handleInputChange}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Select Language")}
            items={languageDropDown}
            name="languageId"
            value={userData.languageId}
            onChange={handleInputChange}
            error={errors["languageId"]}
            InputLabelProps={{
              shrink: userData.languageId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Select Calender")}
            items={calendarDropDown}
            name="calendarId"
            required
            value={userData.calendarId}
            error={errors["calendarId"]}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: userData.calendarId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Hijri Calendar Format")}
            items={hijriCalendarDropDown}
            name="hijriDateFormatId"
            value={userData.hijriDateFormatId}
            onChange={handleInputChange}
            error={errors["hijriDateFormatId"]}
            InputLabelProps={{
              shrink: userData.hijriDateFormatId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Gregorian Calendar Format")}
            items={gregorianCalendarDropDown}
            name="gregorianDateFormatId"
            value={userData.gregorianDateFormatId}
            onChange={handleInputChange}
            error={errors["gregorianDateFormatId"]}
            InputLabelProps={{
              shrink: userData.gregorianDateFormatId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={12}>
          <CheckBoxDX
            name="receiveEmailsOnTeamOverdueItems"
            label={t("Receive emails on team's overdue items")}
            checked={userData.receiveEmailsOnTeamOverdueItems}
            onChange={handleInputChange}
          />
        </GridDX>

        <GridDX item xs={12}>
          <CheckBoxDX
            name="receiveEmailsForRequireActionItems"
            label={t("Receive reminders of require-action items")}
            checked={userData.receiveEmailsForRequireActionItems}
            onChange={handleInputChange}
          />
        </GridDX>

        <GridDX item xs={12}>
          <CheckBoxDX
            name="receiveEmailsOnOverdueItems"
            label={t("Receive reminders on overdue and require-action items")}
            checked={userData.receiveEmailsOnOverdueItems}
            onChange={handleInputChange}
          />
        </GridDX>

        <GridDX item xs={12} justifyContent="center">
          <UploadBoxDX
            label={t("Signature")}
            image={userData.signatureImage}
            setImage={(image: any) =>
              setUserData({
                ...userData,
                signatureImage: image,
              })
            }
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default ProfileModal;
